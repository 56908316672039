import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../shared/services/common.service';
import { FormValidateService } from '../../../shared/services/form-validate.service';
import {Router} from '@angular/router';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-role-settings',
  templateUrl: './role-settings.component.html',
  styleUrls: ['./role-settings.component.scss']
})
export class RoleSettingsComponent implements OnInit {
  public employeeRoleAddPopup; deletepopup: boolean = false;
  public RoleAddForm; formGroup: FormGroup;LevelAddForm;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];levelName:string;
  public gridData:GridDataResult;
  public roleID: number;levelAddPopUp:boolean=false;
  private editedRowIndex: number = -1;
  public level:Array<{levelID:number,levelName:string}>=[];
  public levelList:Array<{levelID:0,levelName:''}>=[];
  public status: Array<{ status: string, value: number }> = [
    { status: "Active", value: 1 },
    { status: "Inactive", value: 2 }
  ];
  constructor(private fb: FormBuilder, private CommonService: CommonService, private FormValidateService: FormValidateService,private Router:Router) {
    
    this.RoleAddForm = this.fb.group({
      roleName: ['', [Validators.required]],
      status: [null, [Validators.required]],
      levelID:[null,[Validators.required]]
    });
    this.LevelAddForm=this.fb.group({
      levelName: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.LevelsGET();   
    
  }

  //To retrieve roles
  RolesGet() {
    this.CommonService.getService("api/Management/RolesGET", "").subscribe((result: any) => {
      if (result.code == 1) {
        var roles = [];var levelName='No Level';
        for (var v in result.data) {
          if(this.levelList.length>0&&result.data[v].levelID!=null){
            let level=this.levelList.filter(item=>item.levelID==result.data[v].levelID);
            levelName=level[0].levelName;
          }
          else{
            this.LevelsGET();
          }            
          roles.push({ "roleID": result.data[v].roleID, "roleName": result.data[v].roleName, 
          "status": result.data[v].status == 1 ? result.data[v].status = 'Active' : result.data[v].status = 'InActive',"levelID": levelName}
          )
        } 
        if(roles!=null && roles.length>0){
          this.items = roles;
          this.items.length > 10? this.pageable = true : this.pageable = false;
          this.loadItems(0);
        }
        else{
          this.items=[];  
          this.loadItems(0);       
        }       
      }
      else if(result.code==0){
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!Oops there was an error please try after some time...!", "error")
      }      
    });
  }

  //#region Paging functionality
    private loadItems(value:number): void {
      if(value==1)
      this.skip=0;
      this.gridData = {
        data: this.items.slice(this.skip, this.skip + this.pageSize),
        total: this.items.length
      };
    }
  
    public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.loadItems(0);
    }
  //#endregion

  

  //add popup

  AddPopUp() {
    this.RoleAddForm.reset();
    this.employeeRoleAddPopup = true;
  }

//#region Level
addNewLevel(){
  this.LevelAddForm.reset();
  this.LevelAddForm.controls.levelName.setValue(this.levelName);
this.levelAddPopUp=true;
}

LevelADD(){
 var objLevel={
    levelName:this.LevelAddForm.value.levelName
  }
  this.CommonService.postService('api/Management/LevelADD',objLevel)
  .subscribe((response:any)=>{
    this.LevelsGET();
  this.RolesGet();  
  });
  this.levelAddPopUp=false;
}


LevelsGET(){
  this.CommonService.getService('api/Management/LevelsGET','')
  .subscribe((response:any)=>{    
    var levels=[];
      response.data.forEach(element => {
        levels.push({levelID:element.levelID,levelName:element.levelName});
      });
    this.levelList=levels;
    if(this.levelList!=undefined&&this.levelList.length>0)
    this.level=this.levelList.slice(0);
    this.RolesGet();
  })
    }
  
    public handleFilter(value) {
      this.levelName = value;
      this.level = this.levelList.filter((s) => s.levelName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  //#endregion


  //#region add functionality

  RoleAdd() {
    this.FormValidateService.validateAllFormFields_Empty(this.RoleAddForm);
    if (this.RoleAddForm.valid) {
      var objRole = {
        roleName: this.RoleAddForm.controls.roleName.value,
        status: this.RoleAddForm.controls.status.value.value,
        levelID:this.RoleAddForm.controls.levelID.value.levelID
      }
      this.CommonService.postService("api/Management/EmployeeRoleADD", objRole).subscribe((result: any) => {
        if (result.code == 1) {          
;          this.employeeRoleAddPopup = false;
          this.CommonService.displayMessage("Added Successfully !!!", "success");
          this.RolesGet();
        }
        else if (result.code == -8) {
          this.CommonService.displayMessage("Already Exists !!!", "error");
        }
        else if(result.code==0){
          this.Router.navigateByUrl('sign-in');
        }
        else {
          this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
      });
    }
    else {
      this.FormValidateService.validateAllFormFields(this.RoleAddForm);
    }
  }
  //#endregion


  //edit functionality
  editHandler({ sender, rowIndex, dataItem }) {
    var level;
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;    
       level= this.levelList.filter(item=>item.levelName==dataItem.levelID)  
  
    this.formGroup = new FormGroup({
      'roleID': new FormControl(dataItem.roleID, Validators.required),
      'roleName': new FormControl(dataItem.roleName, Validators.required),
      'status': new FormControl({ "status": dataItem.status, "value": dataItem.status == 'Active' ? 1 : 2 }, Validators.required),
      'levelID':new FormControl({'levelID':level.levelID==0?level.levelID:level[0].levelID,levelName:dataItem.levelID})
    });
    sender.editRow(rowIndex, this.formGroup);
  }

  //update functionality
  public saveHandler({ sender, rowIndex, formGroup, dataItem }) {
    var Role = {
      "roleName": formGroup.value.roleName,
      "levelID":formGroup.value.levelID.levelID,
      "status": formGroup.value.status.value,
      "roleID": formGroup.value.roleID,
    }
    this.CommonService.postService("api/Management/RoleUPDATE", Role).subscribe((result: any) => {
      if (result.code == 1) {
        sender.closeRow(rowIndex);
        this.CommonService.displayMessage("Updated Successfully..!!!", "success");
        this.RolesGet();
      }
      else if (result.code == -8) {
        this.CommonService.displayMessage("Already exists..!!!", "error");
      }
      else if(result.code==0){
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    })
  }

  //close editor

  public cancelHandler({ sender, rowIndex }): void {
    this.closeEditor(sender);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex): void {
    grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //delete popup

  DeletePopup(roleID) {
    this.roleID = roleID;
    this.deletepopup = true;
  }
  
  //delete functionality
  Delete() {
    this.CommonService.getService("api/Management/RoleDELETE", "roleID=" + this.roleID).subscribe((result: any) => {
      if (result.code == 1) {
        this.loadItems(1);
        this.deletepopup = false;
        this.CommonService.displayMessage("Deleted Successfully..!!!", "success");
        this.RolesGet();
      }
      else if(result.code==0){
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }



  //to close popup
  close() {
    this.employeeRoleAddPopup = false;
    this.deletepopup = false;
    this.levelAddPopUp=false;
  }
}
