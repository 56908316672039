import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { UserComponent } from '../user.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {FormValidateService} from '../../shared/services/form-validate.service';

@Component({
  selector: 'app-approve-reject-leave',
  templateUrl: './approve-reject-leave.component.html',
  styleUrls: ['./approve-reject-leave.component.scss']
})
export class ApproveRejectLeaveComponent implements OnInit {
  public leavesData: GridDataResult;
  public ApproveRejectForm: FormGroup;
  public leavePopup: boolean = false;
  public fromMinDate; toMaxDate; fromMin; toMax: Date;
  public leaveID: any;
  public pageable: boolean = false;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public remarksBlock; datePickerBlock: boolean = false;approveLOPpopup:boolean=false;
  public statusList: Array<{ text: string, value: number }> = [];
  constructor(private CommonService: CommonService, private UserComponent: UserComponent, private fb: FormBuilder, private Router: Router,
    private FormValidateService:FormValidateService) {
    this.ApproveRejectForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
      status: [null, [Validators.required]],
      remarks: ['', [Validators.required]]
    });
    this.statusList = [{ text: "Approve", value: 2 }, { text: "Reject", value: 3 }]
  }

  ngOnInit() {
    this.UserComponent.leaves = true;
    this.leavesGet();
  }

  //To retrieve leaves

  leavesGet() {
    this.CommonService.getService("api/Employee/approveRejectLeavesGet", "").subscribe((result: any) => {
      if (result.code == 1) {
        var leavesDetails=[];
        leavesDetails= result.data;
        if(leavesDetails!=null){
          this.items = leavesDetails;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0);
          console.log(this.items);
        }
        else{
          this.items=[];
          this.loadItems(0);
        }

      }
      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }     
    });
  }

  //paging
  private loadItems(type:any): void {
    if(type==1)
    this.skip=0;
    this.leavesData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }

  //datepicker value change method

  fromDateChange(date) {
    this.fromMin = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  //Status change method

  statusChange(status) {
    if (status.value == 2) {
      this.datePickerBlock = true;
      this.remarksBlock = false;
      this.ApproveRejectForm.controls.fromDate.clearValidators();
      this.ApproveRejectForm.controls.fromDate.updateValueAndValidity();
      this.ApproveRejectForm.controls.toDate.clearValidators();
      this.ApproveRejectForm.controls.toDate.updateValueAndValidity();
      this.ApproveRejectForm.controls.remarks.clearValidators();
      this.ApproveRejectForm.controls.remarks.updateValueAndValidity();
    }
    else {
      this.remarksBlock = true;
      this.datePickerBlock = false;
      this.ApproveRejectForm.controls.fromDate.clearValidators();
      this.ApproveRejectForm.controls.fromDate.updateValueAndValidity();
      this.ApproveRejectForm.controls.toDate.clearValidators();
      this.ApproveRejectForm.controls.toDate.updateValueAndValidity();
      this.ApproveRejectForm.controls.remarks.setValidators(Validators.required);
      this.ApproveRejectForm.controls.remarks.updateValueAndValidity();
    }
  }

  //update popup

  approveRejectLeavepopup(leaveDetails) {
    this.leaveID = leaveDetails.leaveID;
    this.ApproveRejectForm.reset();
    debugger;
    this.fromMinDate = this.CommonService.DateTimeConversion(leaveDetails.fromDate);
    this.toMaxDate =  this.CommonService.DateTimeConversion(leaveDetails.toDate);
    this.fromMin =  this.CommonService.DateTimeConversion(leaveDetails.fromDate);
    this.toMax =  this.CommonService.DateTimeConversion(leaveDetails.toDate);
    this.ApproveRejectForm.controls.fromDate.setValue(new Date(leaveDetails.fromDate));
    this.ApproveRejectForm.controls.toDate.setValue(new Date(leaveDetails.toDate));
    this.remarksBlock = false;
    this.datePickerBlock = false;
    this.leavePopup = true;
  }

  //update approve/reject leave functionaality.

  approveRejectLeave() {
    if(this.ApproveRejectForm.valid){
      let objLeave = {
        fromDate: this.ApproveRejectForm.value.fromDate,
        toDate: this.ApproveRejectForm.value.toDate,
        status: this.ApproveRejectForm.value.status.value,
        remarks: this.ApproveRejectForm.value.remarks,
        leaveID: this.leaveID
      }
      this.CommonService.postService("api/Employee/approveRejectLeaveUpdate", objLeave).subscribe((result: any) => {
        if (result.code == 1) {
          this.loadItems(1);
          this.leavePopup = false;
          this.CommonService.displayMessage("Updated Successfully..!!", "success");
          this.leavesGet();
        }
        else if(result.code==-7){
          this.leavePopup = false;
          this.approveLOPpopup=true;  
        }
        else if (result.code == -8) {
          this.CommonService.displayMessage("Already other member had approved in this team", "error");
        }
        else if(result.code==0){
          this.Router.navigateByUrl('sign-in');
        }
        else {
          this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
      });
    }
    else{
      this.FormValidateService.validateAllFormFields(this.ApproveRejectForm);
    }
  }

  //#region Loss Of Pay
  ApproveLeaveWithLOP(isApproved:number){
   var objLossOfPay={
"leaveID":this.leaveID,
"isApproved":isApproved
    }
    this.CommonService.postService('api/Employee/LeaveWithLOP',objLossOfPay)
    .subscribe((response:any)=>{
      if(response.code==1){
        this.CommonService.displayMessage("Updated Successfully..!!", "success");
        this.leavesGet();
      }

    });
    this.approveLOPpopup=false;
  }
  
  //#endregion

  //To close popup
  close(text:any) {
    this.leavePopup = false;
    if(text=='approveLOPpopup')
    {
      this.ApproveLeaveWithLOP(0);
    }
  }

}
