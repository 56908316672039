import { Component, OnInit } from '@angular/core';
import { CommonService } from "../../../shared/services/common.service";
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FormValidateService } from 'src/app/shared/services/form-validate.service';
import { Router } from '@angular/router';
import{UserComponent} from '../../user.component';

@Component({
  selector: 'app-organization-official-info',
  templateUrl: './organization-official-info.component.html',
  styleUrls: ['./organization-official-info.component.scss']
})
export class OrganizationOfficialInfoComponent implements OnInit {
  public addNewEmployeeOfficialDetailsForm: FormGroup;

  apiMethodUrl: string; employeeID: number  
  public accessLevel: Array<{ accessType: string, accessLevelID: number, accessLevelStatus: number }> = [
  ];
  statuslist: Array<{ text: string, value: number }> = [];
  constructor(private commonService_: CommonService,
    private formValidateService: FormValidateService, private router: Router,private UserComponent:UserComponent) {
    this.accessLevelsGET();
    this.statuslist = [{ text: "Current Employee", value: 1 }, { text: "Ex-Employee", value: 2 }];
  }

  ngOnInit() {
    this.UserComponent.organizationBlock=true;
    this.createAddNewEmployeeOrganizationOfficialDetailsForm();
  }


  //#region AccessLevelGET
  accessLevelsGET() {
    var accessLevelList = [];
    this.commonService_.getService("api/Employee/AccessLevelsGET", '')
      .subscribe((response: any) => {
        if (response.code == 1) {
          var data = response.data;
          data.forEach(element => {
            accessLevelList.push({ "accessLevelID": element.accessLevelID, "accessType": element.accessType, "accessLevelStatus": element.accessLevelStatus })
          });
          this.accessLevel = accessLevelList;
          this.organizationOfficialInfoGET();
        }
        else if(response.code==0){
          this.router.navigateByUrl('sign-in');
        }
        else{
          this.commonService_.displayMessage("!oops There was an error please try after some time....","error"); 
        }
      })
  }
  
  //Add organization details

  public organizationOfficialInfoADD() {
    this.formValidateService.validateAllFormFields_Empty(this.addNewEmployeeOfficialDetailsForm)
    if (this.addNewEmployeeOfficialDetailsForm.controls.aadharNumber.value != null && this.addNewEmployeeOfficialDetailsForm.controls.aadharNumber.value.length == 12) {
      this.addNewEmployeeOfficialDetailsForm.controls.aadharNumber.setValidators(null);
      this.addNewEmployeeOfficialDetailsForm.controls.aadharNumber.updateValueAndValidity();
    }

    if (this.addNewEmployeeOfficialDetailsForm.valid) {
      let addNewEmployeeOfficialDetails = {
        employeeDepartMent: this.addNewEmployeeOfficialDetailsForm.value.employeeDepartMent,
        employeeDesignation: this.addNewEmployeeOfficialDetailsForm.value.employeeDesignation,
        employeePAN: this.addNewEmployeeOfficialDetailsForm.value.employeePAN,
        employeePFAccountNumber: this.addNewEmployeeOfficialDetailsForm.value.employeePFAccountNumber,
        employeeCode: this.addNewEmployeeOfficialDetailsForm.value.employeeCode,
        employeeInternalExtensionNumber: this.addNewEmployeeOfficialDetailsForm.value.employeeInternalExtensionNumber,
        employeeShift: this.addNewEmployeeOfficialDetailsForm.value.employeeShift,
        employeeDOJ: new Date(this.addNewEmployeeOfficialDetailsForm.value.employeeDOJ),
        accessLevelID: this.addNewEmployeeOfficialDetailsForm.value.accessLevelID.accessLevelID,
        employeeBiometricCode: this.addNewEmployeeOfficialDetailsForm.value.employeeBiometricCode,
        aadharNumber: this.addNewEmployeeOfficialDetailsForm.value.aadharNumber,
        rfidNumber: this.addNewEmployeeOfficialDetailsForm.value.RFIDNumber,
        employeeID: this.commonService_.retrieve("E_Val"),
        status: this.addNewEmployeeOfficialDetailsForm.value.Status.value
      }
      this.apiMethodUrl = "api/Employee/OrganizationOfficialDetailsADD"
      this.commonService_.postService(this.apiMethodUrl, addNewEmployeeOfficialDetails)
        .subscribe((result: any) => {
          if (result.code == 1) {
            let type = 'success';
            let content = 'Added employee organization details successfully';
            this.commonService_.displayMessage(content, type);
            this.router.navigateByUrl('user/hr/bank-info');
          }
          else if(result.code===8){
            this.commonService_.displayMessage("Already Exists..!!","error");
          }
          else if(result.code==0){
            this.router.navigateByUrl('sign-in');
          }
          else {
            let type = 'error';
            let content = 'Unable to process your request';
            this.commonService_.displayMessage(content, type)
          }
        });
    }
    else {
      this.formValidateService.validateAllFormFields(this.addNewEmployeeOfficialDetailsForm);
    }
  }

  public itemDisabled(itemArgs: { dataItem: string, index: number }) {
    return itemArgs.dataItem["accessLevelStatus"] == 2;
  }

  //To retrieve organization official info

  public organizationOfficialInfoGET() {
    this.apiMethodUrl = "api/Employee/EmployeeOrganizationOfficialInfoGET";
    this.employeeID = this.commonService_.retrieve("E_Val");
    this.commonService_.getService(this.apiMethodUrl, "ID=" + this.employeeID)
      .subscribe((result: any) => {
        if (result.code == 1) {
          var accessLevelType='';
          if(result.data.accessLevelID!=0){
            let accessLevelID = this.accessLevel.filter(
              item => item.accessLevelID === result.data.accessLevelID);
              accessLevelType=accessLevelID[0].accessType;
          }
          var statusID = this.statuslist.filter(
            item => item.value === result.data.status);
          var obj;

          if (statusID == undefined || statusID.length == 0) {
            obj = null;
          } else {
            obj = statusID[0]
          }
          this.createAddNewEmployeeOrganizationOfficialDetailsForm();
          this.addNewEmployeeOfficialDetailsForm.setValue({
            employeeDepartMent: result.data.employeeDepartment,
            employeeDesignation: result.data.employeeDesignation,
            employeePAN: result.data.employeePAN,
            employeePFAccountNumber: result.data.employeePFAccountNumber,
            aadharNumber: result.data.aadharNumber,
            RFIDNumber: result.data.rfidNumber,
            employeeCode: result.data.employeeCode,
            employeeInternalExtensionNumber: result.data.employeeInternalExtensionNumber,
            employeeShift: result.data.employeeShift,
            employeeDOJ: new Date(result.data.employeeDOJ),
            accessLevelID: { accessType:accessLevelType , accessLevelID: result.data.accessLevelID },
            employeeBiometricCode: result.data.employeeBiometricCode,
            Status: obj
          })
        }
        else if(result.code==0){
          this.router.navigateByUrl('sign-in');
        }
        else {
          let type = 'error';
          let content = 'No details found';
          this.commonService_.displayMessage(content, type)
        }
      });
  }


  createAddNewEmployeeOrganizationOfficialDetailsForm() {
    this.addNewEmployeeOfficialDetailsForm = new FormGroup({
      employeeDepartMent: new FormControl('', Validators.required),
      employeeDesignation: new FormControl('', Validators.required),
      employeePAN: new FormControl('', Validators.required),
      employeePFAccountNumber: new FormControl('', [Validators.required, Validators.min(1)]),
      aadharNumber: new FormControl('', [Validators.required]),
      RFIDNumber: new FormControl('', Validators.required),
      employeeCode: new FormControl('', Validators.required),
      employeeInternalExtensionNumber: new FormControl('', Validators.required),
      employeeShift: new FormControl('', Validators.required),
      employeeDOJ: new FormControl(new Date(), Validators.required),
      accessLevelID: new FormControl({ access: null, value: null }, Validators.required),
      employeeBiometricCode: new FormControl('', Validators.required),
      Status: new FormControl(null, Validators.required)
    });
    this.addNewEmployeeOfficialDetailsForm.reset();
  }
}

