import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementComponent } from './management.component';
import {ManagementRoutingModule} from './management-routing.module';
import { LeavepolicySettingsComponent } from './leavepolicy-settings/leavepolicy-settings.component';
import { IconSettings, IconAlignJustify, IconBell, IconMessageSquare, IconList,IconLogOut,IconEdit,IconTrash2, IconMaximize, IconSearch,IconChevronRight,IconHome,IconSkipForward,IconCircle,IconPlusCircle, IconRefreshCw, IconX, IconXCircle, IconSkipBack, IconAlertTriangle, IconSave, IconTarget, IconLayers, IconAlertCircle,IconSidebar,IconCreditCard} from 'angular-feather';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AccesslevelSettingsComponent } from './accesslevel-settings/accesslevel-settings.component';
import { RoleSettingsComponent } from './role-settings/role-settings.component';

const icons = [
  IconSettings,
  IconAlignJustify,
  IconBell,
  IconCircle,
  IconPlusCircle,
  IconEdit,
  IconTrash2,
  IconList,
  IconSkipForward,
  IconMessageSquare,
  IconLogOut,
  IconMaximize,
  IconSearch,
  IconChevronRight,
  IconHome,
  IconRefreshCw,
  IconXCircle,
  IconSkipForward,
  IconSkipBack,IconAlertTriangle,IconSave,IconTarget,IconLayers,IconAlertCircle,IconTrash2,IconSidebar,IconCreditCard
];

@NgModule({
  declarations: [ManagementComponent,LeavepolicySettingsComponent, AccesslevelSettingsComponent,RoleSettingsComponent],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DialogsModule,
    icons    
  ],
  exports: [
    ManagementComponent,icons
  ]
})
export class ManagementModule { }
