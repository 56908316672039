import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user.component';
import { EmployeeModule } from "./employe/employee/employee.module";
import { ManagementModule } from './management/management.module';
import { HrModule } from './hr/hr.module';
import { ApproveRejectLeaveComponent } from './approve-reject-leave/approve-reject-leave.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { CalendarInfoComponent } from './calendar-info/calendar-info.component';

const routes: Routes = [
    {
        path: '', component: UserComponent,
        children: [                      
            {
                path: 'employee', loadChildren: () => import('./employe/employee/employee.module').then(mod => mod.EmployeeModule)
            },            
            {
                path:'hr',loadChildren:()=>HrModule
            },
            {
                path:'management',loadChildren:()=> import('./management/management.module').then(mod => mod.ManagementModule)
            },
            {
                path:'approve-reject',component:ApproveRejectLeaveComponent,data:{title:'Leave Approve/Reject'}
            },
            {
                path:'leave-request',component:LeaveRequestComponent,data:{title:'Leave Request'}
            },
            {
                path:'events',component:CalendarInfoComponent,data:{title:'Events'}
            }
        ]
    }
];



// const routes: Routes = [
//     {

//         path: "", loadChildren: () => ManagementModule
//     }
// ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UserRoutingModule { }
