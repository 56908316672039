import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../shared/services/common.service';
import { FormValidateService } from '../../../shared/services/form-validate.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from "@angular/router";

@Component({
  selector: 'app-leavepolicy-settings',
  templateUrl: './leavepolicy-settings.component.html',
  styleUrls: ['./leavepolicy-settings.component.scss']
})
export class LeavepolicySettingsComponent implements OnInit {

  public days: any = [];
  public PolicyAddForm: FormGroup;
  public gridData: GridDataResult;
  public pageable: boolean = false;
  public pageSize = 10;policyID:any;
  public skip = 0;
  private items: any[];
  public PolicyAddPopup;deletepopup: boolean = false;
  public status: Array<{ status: string, value: number }> = [
    { status: "Active", value: 1 },
    { status: "Inactive", value: 2 }
  ];

  constructor(private fb: FormBuilder, private FormValidateService: FormValidateService, private CommonService: CommonService, private Router: Router) {
    this.PolicyAddForm = this.fb.group({
      policyName: ['', [Validators.required]],
      days: [null, [Validators.required]],
      status: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.Policies_Get();
  }

  Policies_Get() {
    this.CommonService.getService("api/Management/PoliciesGET", "").subscribe((result: any) => {
      if (result.code == 1) {
        var leavePolicy = [];
        for (var v in result.data) {
          leavePolicy.push({ "policyID": result.data[v].policyID, "policyName": result.data[v].policyName, "status": result.data[v].status == 1 ? result.data[v].status = 'Active' : result.data[v].status = 'InActive', "days": result.data[v].days })
        }
        if (leavePolicy != null) {
          this.items = leavePolicy;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0);
        }
        else {
          this.items = [];
          this.loadItems(0);
        }
      }
      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!Oops there was an error please try after some time...!", "error");
      }
    });
  }


  //paging

  private loadItems(type: any): void {
    if (type == 1)
      this.skip = 0;
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }


  //days
  Days() {
    for (var i = 12; i <= 31; i++) {
      this.days.push(i);
    }
  }

  //to open add popup
  Add() {
    this.PolicyAddForm.reset();
    this.Days();
    this.PolicyAddPopup = true;
  }


  policyAdd() {
    this.FormValidateService.validateAllFormFields_Empty(this.PolicyAddForm);
    if (this.PolicyAddForm.valid) {
      var objPolicySettings = {
        policyName: this.PolicyAddForm.value.policyName,
        days: this.PolicyAddForm.value.days,
        status: this.PolicyAddForm.value.status.value
      }
      this.CommonService.postService("api/Management/PolicyADD", objPolicySettings).subscribe((result: any) => {
        if (result.code == 1) {
          this.PolicyAddPopup = false;
          this.CommonService.displayMessage("Added Successfully..!!", "success");
          this.Policies_Get();
        }
        else if (result.code == -8) {
          this.CommonService.displayMessage("Already Exists...!!", "error");
        }
        else {
          this.CommonService.displayMessage("!Oops there was an error please try after some time...!", "error");
        }
      });
    }
    else {
      this.FormValidateService.validateAllFormFields(this.PolicyAddForm);
    }
  }

//delete popup

DeletePopup(ID){
this.policyID=ID;
this.deletepopup=true;
}

//Delete functionality
Delete(){
this.CommonService.getService("api/Management/PolicyDELETE","policyID="+this.policyID).subscribe((result:any)=>{
if(result.code==1){
  this.loadItems(1);
  this.deletepopup=false;
this.CommonService.displayMessage("Deleted Successfully...!!!","success");
this.Policies_Get();
}
else if(result.code==0){
  this.Router.navigateByUrl('sign-in');
}
else{
  this.CommonService.displayMessage("!Oops there was an error please try after some time...!", "error");
}
});
}

  //close popup
  close() {
    this.PolicyAddPopup = false;
    this.deletepopup=false;
  }
}
