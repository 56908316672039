import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service';
import { CommonService } from '../../../../shared/services/common.service';
import { Router } from "@angular/router";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";


@Component({
  selector: 'app-experience-info',
  templateUrl: './experience-info.component.html',
  styleUrls: ['./experience-info.component.scss']
})
export class ExperienceInfoComponent implements OnInit {

  public experienceInfoPopup; deletepopup: boolean = false;
  public ExperienceInfoForm: FormGroup;
  public ExperienceDetails:GridDataResult;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public minDate;toMaxDate:Date;
  public experienceID: any;
  public empID:any=this.CommonService.retrieve("E_Val");

  constructor(private fb: FormBuilder, private formValidateService: FormValidateService, private CommonService: CommonService, private Router: Router) {
    this.ExperienceInfoForm = this.fb.group({
      companyName: ['', [Validators.required,]],
      designation: ['', [Validators.required,]],
      startDate: ['', [Validators.required]],
      endDate: ['',[Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      currEmployeeID:['']
    });
  }

  ngOnInit() {
    this.empID!=-1? this.ExperienceDetailsGet():this.items=[];
  }

  //To retrieve experience info
  ExperienceDetailsGet() {
    this.CommonService.getService("api/Employee/ExperienceGET", "currEmployeeID="+this.empID).subscribe((result: any) => {
      if (result.code == 1) {
        var experienceDetails=[];
        experienceDetails = result.data;
        if(experienceDetails!=null){
          this.items = experienceDetails;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0);
        }
        else{
          this.items=[];
          this.loadItems(0);
        }
      }
      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }      
    });
  }

    //paging
    private loadItems(type:any): void {
    if(type==1)
    this.skip=0;
      this.ExperienceDetails = {
        data: this.items.slice(this.skip, this.skip + this.pageSize),
        total: this.items.length
      };
    }
  
    public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.loadItems(0);
    }

  //add popup


  AddPopup() {
    this.minDate='';
    this.ExperienceInfoForm.controls.endDate.updateValueAndValidity();
    this.ExperienceInfoForm.reset();
    this.toMaxDate=this.CommonService.DateConversion(new Date());
    this.experienceInfoPopup = true;
  }

  //from date change functionality
  startDateChange(date){
    this.ExperienceInfoForm.controls.endDate.reset();
    this.minDate = this.CommonService.DateConversion(date);   
  }
  //add experience info

  ExperienceAdd() {
    this.formValidateService.validateAllFormFields_Empty(this.ExperienceInfoForm);
    if (this.ExperienceInfoForm.valid) {
      this.ExperienceInfoForm.controls.currEmployeeID.setValue(this.empID); 
      if(this.ExperienceInfoForm.controls.endDate.value!=null){
        this.ExperienceInfoForm.controls.endDate.clearValidators();
        this.ExperienceInfoForm.controls.endDate.updateValueAndValidity();
        this.CommonService.postService("api/Employee/Experience_Add", this.ExperienceInfoForm.value).subscribe((result: any) => {
          if (result.code == 1) {
            this.experienceInfoPopup = false;
            this.CommonService.displayMessage("Added Successfully...!!!", "success");
            this.ExperienceDetailsGet();
          }
          else if (result.code == -8) {
            this.CommonService.displayMessage("Already Exists...!!!", "error");
          }
          else if(result.code==0){
            this.Router.navigateByUrl('sign-in');
          }
          else {
            this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
          }
        });
      }
      else {
        this.formValidateService.validateAllFormFields(this.ExperienceInfoForm);
      }
      }
      else{
        this.ExperienceInfoForm.controls.endDate.setValidators(Validators.required);
        this.ExperienceInfoForm.controls.endDate.updateValueAndValidity();
        this.formValidateService.validateAllFormFields(this.ExperienceInfoForm);
      }
  }

  //delete popup

  DeletePopup(ID) {
    this.experienceID = ID;
    this.deletepopup = true;
  }

  //delete functionality

  Delete() {
    this.CommonService.getService("api/Employee/ExperienceDELETE", "experienceID=" + this.experienceID).subscribe((result: any) => {
      if (result.code == 1) {
        this.loadItems(1);
        this.deletepopup = false;
        this.CommonService.displayMessage("Deleted Successfully...!!!", "success");
        this.ExperienceDetailsGet();
      }
      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }

  //To close popup
  close() {
    this.experienceInfoPopup = false;
    this.deletepopup = false;
  }
}
