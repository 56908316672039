import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormValidateService } from '../../../shared/services/form-validate.service';
import { Router } from "@angular/router";
import {CommonService} from '../../../shared/services/common.service';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-team-allocations',
  templateUrl: './team-allocations.component.html',
  styleUrls: ['./team-allocations.component.scss']
})
export class TeamAllocationsComponent implements OnInit {

  public createTeamPopup = false; teamAllocatePopup = false;EditTeamPopup=false;
  public statusList: Array<{ text: string, value: number }> = [];
  public employeeList: Array<{ text: string, value: number }> = [];
  public teamsList: Array<{ text: string, value: number }> = [];
  public rolesList: Array<{ text: string, value: number }> = [];
  teamsMembersList: Array<{ "teamMemberID": number, "firstName": string }> = [];
  teamMemberIDList: Array<{ "teamMemberID": number, "firstName": string }> = [];
  public CreateTeamForm; formGroup; AllocateTeamForm;EditTeamForm: FormGroup;
  public objTeam: any;
  public teams: GridDataResult;
  public editedRowIndex: number = -1;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public teamID:any;
  teamMembersList: any = [];

  constructor(private fb: FormBuilder,
    private fv: FormValidateService,
    private router: Router,
    private _CommonService: CommonService) {
    this.CreateTeamForm = this.fb.group({
      teamName: new FormControl("", [Validators.required]),
      prefix: new FormControl(""),
      status: new FormControl(null, [Validators.required])
    });
    this.AllocateTeamForm = this.fb.group({
      team: new FormControl(null, [Validators.required]),
      employee: new FormControl(null, [Validators.required]),
      role: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required])
    });
    this.EditTeamForm=this.fb.group({
      teamName: new FormControl("", [Validators.required]),
      prefix: new FormControl(""),
      status: new FormControl(null, [Validators.required]),
      teamMemberID:new FormControl(null)
    });
    this.statusList = [{ text: "Active", value: 1 }, { text: "InActive", value: 2 }]
  }

  ngOnInit() {
    this.teamsget();
  }

  //allocate team popup
  public AllocateTeam() {
    this.employees_Get();
    this.teams_Get();
    this.roles_Get();
    this.AllocateTeamForm.reset();
    this.teamAllocatePopup = true;
  }

  //create team popup

  CreateTeam() {
    this.CreateTeamForm.reset();
    this.createTeamPopup = true
  }

  //close popup
  public close() {
    this.createTeamPopup = false;
    this.teamAllocatePopup = false;
    this.EditTeamPopup=false;
  }

  //team add functionality
  teamAdd() {
    this.fv.validateAllFormFields_Empty(this.CreateTeamForm);
    if (this.CreateTeamForm.valid) {
      this.objTeam = {
        teamName: this.CreateTeamForm.controls.teamName.value,
        teamPrefix: this.CreateTeamForm.controls.prefix.value,
        status: this.CreateTeamForm.controls.status.value.value
      }
      this._CommonService.postService("/api/Hr/TeamADD", JSON.stringify(this.objTeam)).subscribe((result: any) => {
        if (result.code == 1) {
          this._CommonService.displayMessage("Team Added successfully...!", "success");
          this.teamsget();
        }
        else if (result.code == -8) {
          this._CommonService.displayMessage("Already Exists...!!", "error");
        }
        else if (result.code == 0) {
          this.router.navigateByUrl('sign-in');
        }
        else {
          this._CommonService.displayMessage("!Oops there was an error please try after some time...!", "error");
        }
      });
      this.createTeamPopup = false;
    } else {
      this.fv.validateAllFormFields(this.CreateTeamForm);
    }
  }

  //to retrieve teams functionality.
  teamsget() {
    this._CommonService.getService("/api/Hr/TeamsGET", "").subscribe((result: any) => {
      if (result.code == 1) {
        var teams = [];
        for (var v in result.data) {
          teams.push({ "teamID": result.data[v].teamID, "teamName": result.data[v].teamName, "teamPrefix": result.data[v].teamPrefix, "status": result.data[v].status == 1 ? result.data[v].status = 'Active' : result.data[v].status = 'InActive' })
        }
        if(teams!=null){
          this.items = teams;
          this.items.length > 10? this.pageable = true : this.pageable = false;
          this.loadItems();
        }
        else{
          this.items=[];
          this.loadItems();
        }
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this._CommonService.displayMessage("!Oops there was an error please try after some time...!", "error");
      }

    });
  }

  //paging
  private loadItems(): void {
    this.teams = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  //edit functionality
  EditPopup(TeamDetails){
this.teamID=TeamDetails.teamID;
this.EditTeamForm.reset();
this.EditTeamPopup=true;
this.teamMembers_Get();
this.EditTeamForm.controls.teamName.setValue(TeamDetails.teamName);
this.EditTeamForm.controls.prefix.setValue(TeamDetails.teamPrefix);
this.EditTeamForm.controls.status.setValue({ text: TeamDetails.status, value: TeamDetails.status=='Active'?1:2 })
  }

//to get team Members
teamMembers_Get(){
  this._CommonService.getService("api/Hr/TeamMembersGET","teamID="+this.teamID).subscribe((result:any)=>{
    if (result.code == 1) {
      this.teamsMembersList=[];
      var teams = [];
      let data = result.data;
      if(data!=null){
        data.forEach(team => {
          teams.push({ "teamMemberID": team.teamMemberID, "firstName": team.firstName })
        });
        this.teamMemberIDList = teams;
        this.teamsMembersList = teams;
        this.EditTeamForm.controls.teamMemberID.setValue(teams);
      }

    }
  });
}

public filterChange(filter: any) {
  this.teamsMembersList = this.teamMemberIDList.filter((s) => s.firstName.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
}

// public valueChange(value: any) {
//   var teamMemberIDs = [];
//   value.forEach(team => {
//     teamMemberIDs.push(team.teamMemberID);
//   });
//   this.teamMembersList = ("" + teamMemberIDs.join(", "));
// }

//To Update Teams
teamUpdate(){
  this.fv.validateAllFormFields_Empty(this.EditTeamForm);
    if(this.EditTeamForm.valid){
    var teamMemberIDs = [];
    var value=this.EditTeamForm.value.teamMemberID;
    if(value!=null){
      value.forEach(team => {
        teamMemberIDs.push(team.teamMemberID);
      });
      
    }
    this.teamMembersList = ("" + teamMemberIDs.join(", "));
 

    var objTeamMember={
      teamName:this.EditTeamForm.value.teamName,
      teamPrefix:this.EditTeamForm.value.prefix,
      status:this.EditTeamForm.value.status.value,
      teamID:this.teamID,
      teamMemberID:this.teamMembersList
    }
    this._CommonService.postService("api/Hr/TeamUPDATE",objTeamMember).subscribe((result:any)=>{
      if(result.code==1){
        this.EditTeamPopup=false;
        this._CommonService.displayMessage("Team details Updated Suceessfully..","success");
        this.teamsget();
      }
    })
  }
else{
  this.fv.validateAllFormFields(this.EditTeamForm);
}
}



  //To retrieve employees

  employees_Get() {
    this._CommonService.getService("api/Hr/EmployeeListGET", "serachText="+"").subscribe((result: any) => {
      if (result.code == 1) {
        var employees = [];
        var data = result.data;
        data.forEach(element => {
          if (element.currStatus !=2 ) {
            // if(element.teamStatus!=1)
            employees.push({ "value": element.employeeID, "text": element.firstName })
          }
        });
        this.employeeList = employees;
      }
      else if(result.code==0){
        this.router.navigateByUrl('sign-in');
      }
      else{
        this._CommonService.displayMessage("!oops There was an error please try after some time....","error");
      }
    });
  }

  //Retrieving teams
  teams_Get() {
    this._CommonService.getService("api/Hr/TeamsGET", "").subscribe((result: any) => {
      if (result.code == 1) {
        var teams = [];
        var data = result.data;
        data.forEach(element => {
          teams.push({ "value": element.teamID, "text": element.teamName });
        });
        this.teamsList = teams;
      }
      else if(result.code==0){
        this.router.navigateByUrl('sign-in');
      }
      else{
        this._CommonService.displayMessage("!oops There was an error please try after some time....","error");
      }
    });
  }

  // to retrive roles
  roles_Get() {
    this._CommonService.getService("api/Management/RolesGET", "").subscribe((result: any) => {
      if (result.code == 1) {
        var roles = [];
        var data = result.data;
        data.forEach(element => {
          roles.push({ "value": element.roleID, "text": element.roleName });
        });
        this.rolesList = roles;
      }
      else if(result.code==0){
        this.router.navigateByUrl('sign-in');
      }
      else{
        this._CommonService.displayMessage("!oops There was an error please try after some time....","error");
      }
    });
  }

  //to allocate team
  teamAllocateAdd() {
    this.fv.validateAllFormFields_Empty(this.AllocateTeamForm);
    if(this.AllocateTeamForm.valid){
      let objteamAllocate = {
        employeeID: this.AllocateTeamForm.value.employee.value,
        teamID: this.AllocateTeamForm.value.team.value,
        roleID: this.AllocateTeamForm.value.role.value,
        status: this.AllocateTeamForm.value.status.value
      }
      this._CommonService.postService("api/Hr/TeamAllocate", objteamAllocate).subscribe((result: any) => {
        if (result.code == 1) {

          this._CommonService.displayMessage("Added Successfully..!!", "success");
        }
        else if (result.code == -8) {
          this._CommonService.displayMessage("Already Exists...!!", "error");
        }
        else if(result.code==0){
          this.router.navigateByUrl('sign-in');
        }
        else {
          this._CommonService.displayMessage("!Oops there was an error please try after some time...!", "error")
        }
      });
      this.teamAllocatePopup = false;
    }
    else{
      this.fv.validateAllFormFields(this.AllocateTeamForm);
    }
  }
}
