import { Component, OnInit } from '@angular/core'; 
import { CommonService } from "../shared/services/common.service";
import {Router} from '@angular/router';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  accessLevel;
  roleName:any;leaves:boolean;
  userName:string;employeeID:number;
  organizationBlock:boolean=false;userImage:any;
  constructor( private router:Router,private CommonService_:CommonService) { }

  ngOnInit() {
    this.accessLevel=this.CommonService_.retrieve('a_L');
    this.roleName=this.CommonService_.retrieve('r_L');
   this.employeeNameUpdate();
    this.employeeID=this.CommonService_.retrieve('E_Val');
    this.employeeImageGET();
    //(document.getElementById('userProfilePic') as HTMLImageElement).classList.add('MyClass');
  }
  Leaves(){   
    this.leaves=true;
  }

  employeeNameUpdate(){
    this.userName=this.CommonService_.retrieve('u_N');
  }
  refreshMenu(){
   //(document.getElementById('sideMenu') as HTMLUListElement).className.replace('pcoded-hasmenu disabled', 'pcoded-hasmenu');   
   (document.getElementById('sideMenu') as HTMLUListElement).style.cssText="pointer-events:all";
  }
employeeImageGET(){
  this.CommonService_.getService('api/Employee/EmployeeImageGET','')
  .subscribe((response:any)=>{
    if(response.data!=null){      
      this.userImage='data:image/jpeg;base64,'+response.data;
    }
   
  })
}
}
