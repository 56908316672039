import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service';
import { Router } from "@angular/router";
import { CommonService } from '../../../../shared/services/common.service';
import { UserComponent } from '../../../user.component';
import { FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-ites-employee-basicinfo',
  templateUrl: './ites-employee-basicinfo.component.html',
  styleUrls: ['./ites-employee-basicinfo.component.scss']
})
export class ItesEmployeeBasicinfoComponent implements OnInit {
  
  documentInfoADDForm: FormGroup;
  public fileData: FormData;
  documentInfoAddpopup: boolean = false; uploadedFile: any; fileUploadURL: any;fileInfo: any = { employeeID: 0, fileID: 0 };
  public uploadRestrictions: FileRestrictions = { allowedExtensions: ['.csv'], maxFileSize: 614400 };
  public uploadSaveUrl = environment.appUrl + "api/Hr/employeeDocumentUpload";
  public uploadRemoveUrl = 'uploadRemoveUrl';

  //Global Variable
  apiMethodUrl: string;
  objBasicInfo: any;
  objAddressInfo: any;

  //maritalStatusVariables
  maritalStatusList: Array<{ text: string, value: number }> = [];
  defaultStatus: any = { text: "Select Marital Status", value: 0 }

  public basicInfoForm: FormGroup;
  public state: boolean = false; isDisabled: boolean = true;employeeID: number;
  public disableEmail: boolean = false;
  public toMaxDate: Date;
  //GenderList Variables
  genderList: Array<{ text: string, value: number }> = [];
  defaultGender: any = { text: "Select Gender", value: 0 }


  constructor(
    private fb: FormBuilder,
    private fv: FormValidateService,
    private router: Router,
    private commonService_: CommonService,
    private UserComponent: UserComponent,
   
  ) {
    this.maritalStatusList = [{ text: "Married", value: 1 }, { text: "Widowed", value: 2 }, { text: "Separated", value: 3 }, { text: "Divorced", value: 4 }, { text: "Single", value: 5 }];
    this.genderList = [{ text: "Male", value: 1 }, { text: "Female", value: 2 }]
    this.basicInfoForm = this.fb.group({
      emailAddress: new FormControl(''),
      alternativeEmailAddress: new FormControl(''),
      empCode: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      phoneNumber: new FormControl('', [Validators.required]),
      middleName: new FormControl(''),
      nationality: new FormControl('', [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      maritalStatus: new FormControl(null),
      dateOfBirth: new FormControl('', [Validators.required]),
      anniversaryDate: new FormControl(new Date(1900, 0, 1)),
      bloodGroup: new FormControl('', [Validators.required]),
      alternativePhoneNumber: new FormControl(''),
      passportNumber: new FormControl(''),

      addressLine1: new FormControl('' ),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),

      curaddressLine1: new FormControl(''),
      curaddressLine2: new FormControl(''),
      curcity: new FormControl(''),
      curstate: new FormControl(''),
      curzip: new FormControl('')
    });
  }

  ngOnInit() {
    this.basicInfoForm.reset();
    this.basicInfoForm.controls.phoneNumber.setValue("+91-");
    this.basicInfoForm.controls.nationality.setValue("INDIAN");
    this.toMaxDate = this.commonService_.DateConversion(new Date());
    this.employeeID = this.commonService_.retrieve('E_Val');
  }

  maritalStatusChange(maritalStatus) {
    if (maritalStatus.value == 5) {
      this.isDisabled = true;
      this.basicInfoForm.controls.anniversaryDate.clearValidators();
      this.basicInfoForm.controls.anniversaryDate.updateValueAndValidity();
    }
    else {
      this.isDisabled = false;
      this.basicInfoForm.controls.anniversaryDate.setValidators(Validators.required);
      this.basicInfoForm.controls.anniversaryDate.updateValueAndValidity();
    }
  }

  populateCurrentAddress(event) {
    if (event.target.checked) {
      this.basicInfoForm.controls.curaddressLine1.setValue(this.basicInfoForm.controls.addressLine1.value);
      this.basicInfoForm.controls.curaddressLine2.setValue(this.basicInfoForm.controls.addressLine2.value);
      this.basicInfoForm.controls.curcity.setValue(this.basicInfoForm.controls.city.value);
      this.basicInfoForm.controls.curstate.setValue(this.basicInfoForm.controls.state.value);
      this.basicInfoForm.controls.curzip.setValue(this.basicInfoForm.controls.zip.value);

    } else {
      this.state = false;
      this.basicInfoForm.controls.curaddressLine1.setValue('');
      this.basicInfoForm.controls.curaddressLine2.setValue('');
      this.basicInfoForm.controls.curcity.setValue('');
      this.basicInfoForm.controls.curstate.setValue('');
      this.basicInfoForm.controls.curzip.setValue('');
    }
  }

  basicInfo() {
    this.fv.validateAllFormFields_Empty(this.basicInfoForm);
    if (this.basicInfoForm.valid) {
      var addresses: any = []
      this.objAddressInfo = {
        addressLine1: this.basicInfoForm.controls.addressLine1.value,
        addressLine2: this.basicInfoForm.controls.addressLine2.value,
        city: this.basicInfoForm.controls.city.value,
        state: this.basicInfoForm.controls.state.value,
        zip: this.basicInfoForm.controls.zip.value
      }
      addresses.push(this.objAddressInfo);
      if (this.state == true) { addresses.push(this.objAddressInfo); }
      else {
        this.objAddressInfo = {
          addressLine1: this.basicInfoForm.controls.curaddressLine1.value,
          addressLine2: this.basicInfoForm.controls.curaddressLine2.value,
          city: this.basicInfoForm.controls.curcity.value,
          state: this.basicInfoForm.controls.curstate.value,
          zip: this.basicInfoForm.controls.curzip.value
        }
      }

      addresses.push(this.objAddressInfo);
      this.objBasicInfo = {
        empCode: this.basicInfoForm.controls.empCode.value,
        firstName: this.basicInfoForm.controls.firstName.value,
        lastName: this.basicInfoForm.controls.lastName.value,
        emailAddress: this.basicInfoForm.controls.emailAddress.value,
        phoneNumber: this.basicInfoForm.controls.phoneNumber.value,
        middleName: this.basicInfoForm.controls.middleName.value,
        nationality: this.basicInfoForm.controls.nationality.value,
        gender: this.basicInfoForm.controls.gender.value.text,
        maritalStatus: this.basicInfoForm.controls.maritalStatus.value!=undefined? this.basicInfoForm.controls.maritalStatus.value.text:"",
        dateOfBirth: new Date(this.basicInfoForm.controls.dateOfBirth.value).toLocaleDateString(),
        anniversaryDate: this.basicInfoForm.controls.maritalStatus.value==undefined || this.basicInfoForm.controls.maritalStatus.value.value == 5 ? "" : new Date(this.basicInfoForm.controls.anniversaryDate.value).toLocaleDateString(),
        bloodGroup: this.basicInfoForm.controls.bloodGroup.value,
        alternativePhoneNumber: this.basicInfoForm.controls.alternativePhoneNumber.value,
        alternativeEmailAddress: this.basicInfoForm.controls.alternativeEmailAddress.value,
        passportNumber: this.basicInfoForm.controls.passportNumber.value,
        currEmployeeID: this.commonService_.retrieve("E_Val"),
        addressList: addresses
      };
      this.apiMethodUrl = "api/Employee/EmployeeBasicInfoAdd";
      this.commonService_.postService(this.apiMethodUrl, JSON.stringify(this.objBasicInfo))
        .subscribe((result: any) => {
          if (result.code == 1) {
            this.commonService_.displayMessage("Basic information Updated Successfully !!!", "success");
          }
          else if (result.code == -8) {
            this.commonService_.displayMessage("Already exists...!!", "error");
          }
          else if (result.code == 0) {
            this.router.navigateByUrl('sign-in');
          }
          else {
            this.commonService_.displayMessage("!oops There was an error please try after some time....", "error");
          }
        });
    } else {
      this.fv.validateAllFormFields(this.basicInfoForm);
    }
  }
  addDocumentInfoPopup() {
    this.CreateAddDocumentsForm();
    this.documentInfoAddpopup = true;
    this.uploadedFile = "";
  }
  CreateAddDocumentsForm() {
    this.documentInfoADDForm = new FormGroup({
      fileName: new FormControl('', [Validators.required]),
      fileType: new FormControl(''),
      filePath: new FormControl('',[Validators.required])
    })
    this.documentInfoADDForm.reset();
  }

  close() {
    this.documentInfoAddpopup = false;
  }
  uploadFileEventHandler(e: UploadEvent) {
    const that = this;
    this.documentInfoADDForm.value.fileType="excel";
    let randomValue = Math.floor((Math.random() * 10000))
    let fileType = this.documentInfoADDForm.value.fileType;
    let extension = e.files[0].extension;
    e.data = {
      description: this.employeeID + '_' + randomValue + '_' + fileType + extension
    };
  }
  uploadFileURL() {
    this.fileUploadURL = environment.appUrl + "api/Hr/Doc?employeeID=" + this.employeeID;
  }

  documentDetailsADD() {
    this.fv.validateAllFormFields_Empty(this.documentInfoADDForm);
    if (this.documentInfoADDForm.valid) {
      this.fileInfo.employeeID = this.employeeID
      this.documentInfoADDForm.addControl("employeeID", new FormControl(this.fileInfo.employeeID));
      this.apiMethodUrl = "api/Hr/Process_ITES_EMPList";
      this.commonService_.postService(this.apiMethodUrl, this.documentInfoADDForm.value)
        .subscribe((result: any) => {
          if (result.code == 1) {
            let type = 'success';
            let content = 'Exported Document successfully';
            this.commonService_.displayMessage(content, type)
          }
        });
      this.documentInfoAddpopup = false;
    }
    else {
      this.fv.validateAllFormFields(this.documentInfoADDForm);
    }
  }
  dowloaddocment(){
    window.open(environment.appUrl+"EmployeeDocuments/ITES EMP.csv");
  }
}
