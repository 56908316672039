import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service';
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
import { CommonService } from '../../../../shared/services/common.service';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.scss']
})
export class FamilyInfoComponent implements OnInit {

  public FamilyInfoForm: FormGroup;
  public apiURL: string = environment.appUrl;
  public empID:any=this._CommonService.retrieve("E_Val");
  public familyInfoAddpopup;deletepopup: boolean = false;
  public familyinfo: GridDataResult;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public familyInfoID:any;
  public toMaxDate:Date;
  constructor(private fb: FormBuilder,
    private fv: FormValidateService,
    private router: Router,
    private _CommonService: CommonService) {

    this.FamilyInfoForm = this.fb.group({
      memberName: new FormControl('', [Validators.required ]),
      dateOfBirth: new FormControl(''),
      occupation: new FormControl('', [Validators.required]),
      relationship: new FormControl('', [Validators.required]),
      employeeID:new FormControl('')
    });
  }

  ngOnInit() {
    this.empID!=-1? this.familyInfoGet():this.items=[];     
  }

  //add popup

  Add() {
    this.FamilyInfoForm.reset();
    this.toMaxDate=this._CommonService.DateConversion(new Date());
    this.familyInfoAddpopup = true;
  }

  //to close popup

  close() {
    this.familyInfoAddpopup = false;
    this.deletepopup=false;
  }

  //family info add functionality

  familyInfoAdd() {
    this.fv.validateAllFormFields_Empty(this.FamilyInfoForm);
    if (this.FamilyInfoForm.valid) {
   
       
      this.FamilyInfoForm.controls.employeeID.setValue(this.empID);      
      this._CommonService.postService("api/Employee/FamilyInfoADD", this.FamilyInfoForm.value).subscribe((result: any) => {
        if (result.code == 1) {
          this.familyInfoAddpopup = false;
          this._CommonService.displayMessage("Added Successfully !!!", "success");
          this.familyInfoGet();
        }
        else if (result.code == -8) {
          this._CommonService.displayMessage("Already Exists..!!", "error");
        }
        else if (result.code == 0) {
          this.router.navigateByUrl('sign-in');
        }
        else {
          this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
      });     
    } else {
      this.fv.validateAllFormFields(this.FamilyInfoForm);
    }
  }

  //To retrieve family info

  familyInfoGet() {
    this._CommonService.getService("api/Employee/FamilyInfoGET", "currEmployeeID="+this.empID).subscribe((result: any) => {
      if (result.code == 1) {
        if(result.data!=undefined && result.data.length>0){
        var familyDetails = [];
        for (var i = 0; i < result.data.length; i++) {
          result.data[i].dateOfBirth = new Date().toLocaleDateString() == new Date(result.data[i].dateOfBirth).toLocaleDateString() ? "" : result.data[i].dateOfBirth;
        }
        familyDetails = result.data;
        if (familyDetails != null) {
          this.items = familyDetails;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0);
        }
        else {
          this.items = [];
          this.loadItems(0);
        }
      }else{
        
        this.items = [];
        this.loadItems(0);
      }
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }


  //paging
  private loadItems(type: any): void {
    if (type == 1)
      this.skip = 0;
    this.familyinfo = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }

  //delete functionality

  deletePopup(ID){
    this.familyInfoID=ID;
    this.deletepopup=true;
  }

  Delete() {
    this._CommonService.getService("api/Employee/FamilyInfoDelete", "familyInfoID=" + this.familyInfoID).subscribe((result: any) => {
      if (result.code == 1) {
       this.loadItems(1);
       this.deletepopup=false;
        this._CommonService.displayMessage("Deleted Successfully !!!", "success");
        this.familyInfoGet();
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }

  //page navigation
  pageNavigation() {
    this.router.navigateByUrl('user/employee/emergency-info');
  }
}
