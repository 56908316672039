import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HrComponent } from './hr.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { ProjectAllocationsComponent } from './project-allocations/project-allocations.component';
import { TeamAllocationsComponent } from './team-allocations/team-allocations.component';
import { OrganizationOfficialInfoComponent } from './organization-official-info/organization-official-info.component';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { IdCardGenerationComponent } from './id-card-generation/id-card-generation.component';
import { DocumentInfoComponent } from './document-info/document-info.component';
import { LeaveReportComponent } from './leave-report/leave-report.component';
import { EmployeeAttendanceComponent } from './employee-attendance/employee-attendance.component';


const routes: Routes = [
  {
  path: '', component: HrComponent,
        children: [  
          {
            path:'' ,pathMatch: 'full', component:EmployeeListComponent,data:{title:'Employees'}
        },  
            {
                path:'employee-list' , component:EmployeeListComponent,data:{title:'Employees'}
            },
            {
              path:'project-allocation' , component:ProjectAllocationsComponent,data:{title:'Projects'}
             
          },
          {
            path:'team-allocation' , component:TeamAllocationsComponent,data:{title:'Teams'}
          },
          {
            path:'official-info' , component:OrganizationOfficialInfoComponent,data:{title:'Organization Official Information'}
          },
          {
            
            path:'bank-info' , component:BankInfoComponent,data:{title:'Bank Information'}
          }, 
          {
            path:'generate-id',component:IdCardGenerationComponent,data:{title:'Generate ID'}
          }
          ,
          {
            path:'document-info' , component:DocumentInfoComponent,data:{title:'Document Information'}
          },
          {
            path:'leave-report',component:LeaveReportComponent,data:{title:'Leave Report'}
          },
          {
            path:'employee-attedance',component:EmployeeAttendanceComponent,data:{title:'Employee Attedance'}
          }   
        ]       
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
}) 
export class HRRoutingModule { }
