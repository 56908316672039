import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrComponent } from './hr.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { HRRoutingModule } from './hr-routing.module';
import { IconSettings, IconAlignJustify, IconBell, IconMessageSquare, IconList,IconLogOut,IconEdit,IconTrash2, IconMaximize, IconSearch,IconChevronRight,IconHome,IconSkipForward,IconCircle,IconPlusCircle, IconRefreshCw, IconX, IconXCircle, IconSkipBack, IconAlertTriangle, IconSave, IconTarget, IconLayers, IconAlertCircle,IconSidebar,IconCreditCard,IconImage} from 'angular-feather';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TeamAllocationsComponent } from './team-allocations/team-allocations.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectAllocationsComponent } from './project-allocations/project-allocations.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { OrganizationOfficialInfoComponent } from './organization-official-info/organization-official-info.component';
import { DocumentInfoComponent } from './document-info/document-info.component';
import { IdCardGenerationComponent } from './id-card-generation/id-card-generation.component';
import { BankInfoComponent } from './bank-info/bank-info.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadInterceptor } from '../employe/employee/employee.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { LeaveReportComponent } from './leave-report/leave-report.component';
import { EmployeeAttendanceComponent } from './employee-attendance/employee-attendance.component';
const icons = [
  IconSettings,
  IconAlignJustify,
  IconBell,
  IconCircle,
  IconPlusCircle,
  IconEdit,
  IconTrash2,
  IconList,
  IconSkipForward,
  IconMessageSquare,
  IconLogOut,
  IconMaximize,
  IconSearch,
  IconChevronRight,
  IconHome,
  IconRefreshCw,
  IconXCircle,
  IconSkipForward,
  IconSkipBack,IconAlertTriangle,IconSave,IconTarget,IconLayers,IconAlertCircle,IconTrash2,IconSidebar,IconCreditCard,
  IconImage
];

@NgModule({
  declarations: [HrComponent, EmployeeListComponent, TeamAllocationsComponent, ProjectAllocationsComponent,
    OrganizationOfficialInfoComponent,BankInfoComponent,IdCardGenerationComponent,DocumentInfoComponent, LeaveReportComponent, EmployeeAttendanceComponent
  ],
  imports: [
    CommonModule,
    HRRoutingModule,
    GridModule,
    InputsModule,
    DropDownsModule,
    DateInputsModule,
    ButtonsModule,
    icons,
    DialogModule,
    FormsModule,ReactiveFormsModule,UploadModule
  ],
  exports:[HrComponent,
  icons
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    }
  ],
})
export class HrModule { }
