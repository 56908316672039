import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormValidateService } from 'src/app/shared/services/form-validate.service';
import { CommonService } from '../../../shared/services/common.service';
import { Router } from '@angular/router';
import {UserComponent} from '../../user.component';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.scss']
})
export class BankInfoComponent implements OnInit {

  public BankInfoForm: FormGroup;
  public apiMethodUrl: string;
  constructor(private fb: FormBuilder, private _FormValidateService: FormValidateService, private CommonService: CommonService, private Router: Router,
    private UserComponent:UserComponent) {

    this.BankInfoForm = this.fb.group({
      bankName: ['', [Validators.required,]],
      addressLine1: ['', [Validators.required,]],
      accountNumber: ['', [Validators.required]],
      addressLine2: [''],
      IFSCCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      policyNumber: ['', [Validators.required]],
      state: ['', [Validators.required]],
      nominee: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.UserComponent.organizationBlock=true;
    this.BankInfoGET();
  }

  //To retrieve bank info

  BankInfoGET() {
    this.apiMethodUrl = "api/Employee/BankInfoGET";
    this.CommonService.getService(this.apiMethodUrl, "employeeID=" + this.CommonService.retrieve("E_Val")).subscribe((result: any) => {
      if (result.code == 1) {
        if(result.data!=null){
          this.BankInfoForm.controls.bankName.setValue(result.data.bankName);
          this.BankInfoForm.controls.addressLine1.setValue(result.data.addressLine1);
          this.BankInfoForm.controls.accountNumber.setValue(result.data.accountNumber);
          this.BankInfoForm.controls.addressLine2.setValue(result.data.addressLine2);
          this.BankInfoForm.controls.IFSCCode.setValue(result.data.IFSCCode);
          this.BankInfoForm.controls.city.setValue(result.data.city);
          this.BankInfoForm.controls.policyNumber.setValue(result.data.policyNumber);
          this.BankInfoForm.controls.state.setValue(result.data.state);
          this.BankInfoForm.controls.nominee.setValue(result.data.nominee);
          this.BankInfoForm.controls.zip.setValue(result.data.zip);
        }       
      }
      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }

  //Add bank details
  BankInfoAdd() {
    this._FormValidateService.validateAllFormFields_Empty(this.BankInfoForm);
    if (this.BankInfoForm.valid) {
      this.apiMethodUrl = "api/Employee/BankInfoADD";
      let objBank = {
        bankName: this.BankInfoForm.value.bankName,
        addressLine1: this.BankInfoForm.value.addressLine1,
        accountNumber: this.BankInfoForm.value.accountNumber,
        addressLine2: this.BankInfoForm.value.addressLine2,
        IFSCCode: this.BankInfoForm.value.IFSCCode,
        city: this.BankInfoForm.value.city,
        policyNumber: this.BankInfoForm.value.policyNumber,
        state: this.BankInfoForm.value.state,
        nominee: this.BankInfoForm.value.nominee,
        zip: this.BankInfoForm.value.zip,
        employeeID: this.CommonService.retrieve("E_Val")
      }
      this.CommonService.postService(this.apiMethodUrl, objBank)
        .subscribe((result: any) => {
          if (result.code == 1) {
            this.CommonService.displayMessage("Added Successfully !!!", "success");
            this.Router.navigateByUrl('user/hr/document-info');
            this.BankInfoGET();
          }
          else if (result.code == -8) {
            this.CommonService.displayMessage("Already Exists...!!", "error");
          }
          else if (result.code == 0) {
            this.Router.navigateByUrl('sign-in');
          }
          else {
            this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
          }
        });
    }
    else {
      this._FormValidateService.validateAllFormFields(this.BankInfoForm);
    }

  }

}
