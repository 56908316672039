import { Component, OnInit } from '@angular/core';
import { CommonService } from "../../shared/services/common.service";
@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(private CommonService_:CommonService) { }

  ngOnInit() {
    this.CommonService_.clear();
  }

}
