import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { FormValidateService } from "../../../shared/services/form-validate.service";
import { CommonService } from "../../../shared/services/common.service";
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accesslevel-settings',
  templateUrl: './accesslevel-settings.component.html',
  styleUrls: ['./accesslevel-settings.component.scss']
})
export class AccesslevelSettingsComponent implements OnInit {
  accessLevelAddForm: FormGroup;
  updateAccessLevelForm: FormGroup;

  public gridView: GridDataResult;
  public pageSize = 10; apiMethodUrl: string;
  public skip = 0; confirm: boolean = false;
  private items: any[]; pageable: boolean = false;
  accessLevelAddPopup: boolean = false; accessLevelEditPopup: boolean = false;
  accessLevelStatus: Array<{ "status": string, "value": number }> = [{ status: "Active", value: 1 }, { status: "InActive", value: 2 }];
  accessLevel: Array<{ "accessLevelID": number, "accessType": string, "accessDescription": string, "accessLevelStatus": number }>;
  constructor(private formaValidateService: FormValidateService, private commonService_: CommonService, private Router: Router) { }

  ngOnInit() {
    this.accessLevelsGET();
  }
  //#region Access Level ADD
  ADDAccessLevelPopup() {
    this.createAccessLevelADDForm();
    this.accessLevelAddPopup = true;
  }

  accessLevelADD() {
    this.formaValidateService.validateAllFormFields_Empty(this.accessLevelAddForm);
    if (this.accessLevelAddForm.valid) {
      this.commonService_.postService("api/Employee/AccessLevelADD", this.accessLevelAddForm.value)
        .subscribe((response: any) => {
          if (response.code == 1) {
            this.commonService_.displayMessage("Access level added successfully", 'success');
            this.accessLevelsGET();
          }
          else if (response.code == 0) {
            this.Router.navigateByUrl('sign-in');
          }
          else {
            this.commonService_.displayMessage("Unable to process your request", 'error')
          }
        });
      this.accessLevelAddPopup = false;
    }
    else {
      this.formaValidateService.validateAllFormFields(this.accessLevelAddForm);
    }
  }
  //#endregion


  //#region AccessLevelGET
  accessLevelsGET() {
    var accessLevelList = [];
    this.commonService_.getService("api/Employee/AccessLevelsGET", '')
      .subscribe((response: any) => {
        if (response.code == 1) {
          var data = response.data;
          data.forEach(element => {
            var status = this.accessLevelStatus.filter(
              item => item.value === element.accessLevelStatus);
            accessLevelList.push({
              "accessLevelID": element.accessLevelID,
              "accessType": element.accessType, "accessDescription": element.accessDescription, "accessLevelStatus": status[0].status
            });
          });
          this.accessLevel = accessLevelList;
          if (this.accessLevel != null) {
            this.items = this.accessLevel;
            this.items.length > 10 ? this.pageable = true : this.pageable = false;
            this.loadItems(0);
          }
          else {
            this.items = [];
            this.loadItems(0)
          }
        }
        else if (response.code == 0) {
          this.Router.navigateByUrl('sign-in');
        }
        else {
          this.commonService_.displayMessage("!oops There was an error please try after some time....", "error");
        }
      })
  }
  //#endregion


  //#region UpdateAccessLevel
  UpdateAccessLevelPopup(data: any) {
    this.createAccessLevelUPDATEForm();
    this.updateAccessLevelForm.addControl("accessLevelID", new FormControl(''))
    var status = this.accessLevelStatus.filter(
      item => item.status === data.accessLevelStatus);
    this.updateAccessLevelForm.setValue({
      accessType: data.accessType,
      accessLevelStatus: { "status": data.accessLevelStatus, "value": status[0].value },
      accessLevelID: data.accessLevelID,
      accessDescription:data.accessDescription
    })
    this.accessLevelEditPopup = true;
  }

  accessLevelUpdate() {
    this.formaValidateService.validateAllFormFields_Empty(this.updateAccessLevelForm);
    if (this.updateAccessLevelForm.valid) {
      var accessLevelStatus = {
        "accessType": this.updateAccessLevelForm.controls["accessType"].value,
        "accessLevelStatus": this.updateAccessLevelForm.controls["accessLevelStatus"].value.value,
        "accessLevelID": this.updateAccessLevelForm.controls["accessLevelID"].value,
        "accessDescription": this.updateAccessLevelForm.controls["accessDescription"].value
      }
      this.commonService_.postService("api/Employee/AccessLevelsUPDATE", accessLevelStatus)
        .subscribe((response: any) => {
          if (response.code == 1) {
            this.commonService_.displayMessage("Updated accessLevel Successfully", 'success');
            this.accessLevelsGET();
          }
          else if(response.code==-8){
            this.commonService_.displayMessage("Already Exists..!!","error");
          }
          else if(response.code==0){
            this.Router.navigateByUrl('sign-in');
          }
          else {
            this.commonService_.displayMessage("Unable to process your request", 'error')
          }
        });
      this.accessLevelEditPopup = false;
    }
    else {
      this.formaValidateService.validateAllFormFields(this.updateAccessLevelForm);
    }

  }
  //#endregion
  //#region Paging Functionality
  private loadItems(type: number): void {
    if (type == 1)
      this.skip = 0;
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }
  //#endregion
  close() {
    this.accessLevelAddPopup = false;
    this.accessLevelEditPopup = false;
  }

  createAccessLevelADDForm() {
    this.accessLevelAddForm = new FormGroup({
      accessType: new FormControl('', [Validators.required]),
      accessDescription: new FormControl('', [Validators.required])
    });
    this.accessLevelAddForm.reset();
  }

  createAccessLevelUPDATEForm() {
    this.updateAccessLevelForm = new FormGroup({
      accessType: new FormControl('', [Validators.required]),
      accessLevelStatus: new FormControl(null, [Validators.required]),
      accessDescription:new FormControl('',Validators.required)
    });
    this.updateAccessLevelForm.reset();
  }
}
