// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const  environment = {
  production: false,
  appUrl: "https://ems-api.dit.dharani.org/",
  googleOauthUri: "https://accounts.google.com/o/oauth2/auth",
  scope: "https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email&approval_prompt=force",
  clientID: "1010667325595-cbve2bnugr8j7msue2h219k5s7vu1jdt.apps.googleusercontent.com",
  clientSecret: "zkUJbRfweGiGxO2ec-XmiGMh",
  accessToken_uri: "https://oauth2.googleapis.com/token?",
  redirect_uri: "https://ems.dit.dharani.org/sign-in",
  getGoogleUserDetailsUri: "https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=",
  userOauthUri: "https://ems-api.dit.dharani.org/token",
  calendarScope: "https://www.googleapis.com/auth/calendar.readonly",
  Calendar_redirect_uri: "http://ems.dit.dharani.org//user/events"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
