

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { NotActivatedComponent } from './not-activated/not-activated.component';
import { SignOutComponent } from './sign-out/sign-out.component';


const routes: Routes = [
  {
    path: "", component:SignUpComponent
  },
  {
    path:"sign-up",component:SignUpComponent
  },
  {
      path:"sign-in",component:SignInComponent
  },
  {
    path:"not-activated",component:NotActivatedComponent
},
{
  path:'sign-out',component:SignOutComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
