import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { UserComponent } from '../../user.component';

@Component({
  selector: 'app-leave-report',
  templateUrl: './leave-report.component.html',
  styleUrls: ['./leave-report.component.scss']
})
export class LeaveReportComponent implements OnInit {

  public leavesData: GridDataResult; 
  public pageable: boolean = false; 
  public pageSize = 10;
  public skip = 0;
  private items: any[];

  constructor(private CommonService:CommonService,private Router:Router,private UserComponent:UserComponent) { }

  ngOnInit() {
   this.UserComponent.leaves=true;
    this.leaveReportGET();
  }
  leaveReportGET(){
    this.CommonService.getService("api/Hr/LeaveReportGET","").subscribe((result:any)=>{
      if(result.code==1){
        var leaveReport = [];
        leaveReport = result.data;
        if(leaveReport!=null){
          this.items = leaveReport;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(); 
        }
        else{
          this.items=[];
          this.loadItems();
        }
      }
      else if(result.code==0){
        this.Router.navigateByUrl('sign-in');
      }
      else{
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }

  //paging
  private loadItems(): void {
    this.leavesData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

}
