import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SignInComponent } from './sign-in/sign-in.component';
import { NotActivatedComponent } from './not-activated/not-activated.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { IconLogOut, IconLogIn} from 'angular-feather';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
const icons=[
  IconLogOut,IconLogIn
]

@NgModule({
  declarations: [SignUpComponent, AuthComponent, SignInComponent, NotActivatedComponent, SignOutComponent],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    HttpModule,
    InputsModule,
    ButtonsModule,
    icons,
    DropDownsModule
  ],
  exports:[
    AuthComponent
  ]
})
export class AuthModule { }
