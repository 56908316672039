import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service'
import { Router } from '@angular/router';
import { CommonService } from '../../../../shared/services/common.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-education-info',
  templateUrl: './education-info.component.html',
  styleUrls: ['./education-info.component.scss']
})
export class EducationInfoComponent implements OnInit {

  public educationInfoPopup; deletepopup: boolean = false;
  public gridData: GridDataResult; 
  public pageable: boolean = false; 
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public years: any = [];fromYears;endYears:any=[];
  public EducationInfoForm: FormGroup;
  public educationalInfoID: any;
  public apiMethodUrl: string;
  public empID:any=this.CommonService.retrieve("E_Val");

  constructor(private fb: FormBuilder, private formValidateService: FormValidateService, private CommonService: CommonService, private router: Router) {

    this.EducationInfoForm = this.fb.group({
      degree: ['', [Validators.required,]],
      institutionName: ['', [Validators.required,]],
      stream: ['', [Validators.required]],
      yearOfPassing: ['', [Validators.required]],
      startYear: ['', [Validators.required]],
      endYear: ['', [Validators.required]],
      percentage: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      currEmployeeID:['']
    });
  }

  ngOnInit() {
    this.empID!=-1? this.EducationalInfoGET():this.items=[];   
  }
  //To retrieve educational details

  EducationalInfoGET() {
    this.apiMethodUrl = "api/Employee/EducationalInfoGET";
    this.CommonService.getService(this.apiMethodUrl, "currEmployeeID="+this.empID).subscribe((result: any) => {
      if (result.code == 1) {
        var educationalInfoList = [];
        educationalInfoList = result.data;
        if(educationalInfoList!=null){
          this.items = educationalInfoList;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0); 
        }
        else{
          this.items=[];
          this.loadItems(0);
        }
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }        
    });
  }

  //paging
  private loadItems(type:any): void {
    if(type==1)
    this.skip=0;
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }
  //add pop up

  Add() {
    this.addYears();
    this.EducationInfoForm.reset();
    this.educationInfoPopup = true;
  }

  yearOfPassingChange(year){ 
    this.EducationInfoForm.controls.startYear.reset();
    this.fromYears=[];   
    for (var i = year; i >= 1990; i--) {      
      this.fromYears.push(i);
    }
  }

  startYearChange(year){
    this.EducationInfoForm.controls.endYear.reset();
    this.endYears=[];
    var endYear=this.EducationInfoForm.controls.yearOfPassing.value!=null?this.EducationInfoForm.controls.yearOfPassing.value:new Date().getFullYear();   
    for(var i=endYear;i>=year;i--){
      this.endYears.push(i);
    }
  }
  //educational details add functionality.

  EducationInfoADD() {
    this.formValidateService.validateAllFormFields_Empty(this.EducationInfoForm);
    if(this.EducationInfoForm.valid){
      this.EducationInfoForm.controls.currEmployeeID.setValue(this.empID); 
      this.apiMethodUrl = "api/Employee/EducationalInfoAdd";
      this.CommonService.postService(this.apiMethodUrl, this.EducationInfoForm.value)
        .subscribe((result: any) => {
          if (result.code == 1) {
            this.educationInfoPopup = false;
            this.CommonService.displayMessage("Added Successfully !!!", "success");
            this.EducationalInfoGET();
          }
          else if (result.code == -8) {
            this.CommonService.displayMessage("Already Exists...!!", "error");
          }
          else if (result.code == 0) {
            this.router.navigateByUrl('sign-in');
          }
          else {
            this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
          }
        });
    }
    else{
      this.formValidateService.validateAllFormFields(this.EducationInfoForm);
        }    
  }

  //Delete popup

  DeletePopup(educationInfoID) {
    this.educationalInfoID = educationInfoID;
    this.deletepopup = true;
  }

  //Delete functionality

  Delete() {
    this.apiMethodUrl = "api/Employee/EducationalInfoDelete"
    this.CommonService.getService(this.apiMethodUrl, "educationalInfoID=" + this.educationalInfoID).subscribe((result: any) => {
      if (result.code == 1) {  
        this.loadItems(1);     
        this.deletepopup = false;
        this.CommonService.displayMessage("Deleted Successfully !!!", "success");
        this.EducationalInfoGET();        
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });
  }

  //years drop down
  addYears() {
    this.fromYears=[];
    var year = new Date().getFullYear();
    for (var i = year; i >= 1990; i--) {
      this.years.push(i);
      this.fromYears.push(i);
      this.endYears.push(i);
    }
  }



  //To navigate the page

  pageNavigation() {
    this.router.navigateByUrl('user/employee/experience-info')
  }

  //To close popup
  close() {
    this.educationInfoPopup = false;
    this.deletepopup = false;
  }

}
