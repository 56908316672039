import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeBasicinfoComponent } from './employee-basicinfo/employee-basicinfo.component';
import { EmergencyInfoComponent } from './emergency-info/emergency-info.component';
import { EmployeeComponent,UploadInterceptor } from './employee.component';
import { IconSettings, IconAlignJustify, IconBell, IconMessageSquare, IconList,IconLogOut,IconEdit,IconTrash2, IconMaximize, 
  IconSearch,IconChevronRight,IconHome,IconSkipForward,IconCircle,IconPlusCircle, IconRefreshCw, IconX, IconXCircle, IconSkipBack, IconAlertTriangle, 
  IconSave, IconTarget, IconLayers, IconAlertCircle,IconSidebar,IconCreditCard,IconUserPlus,IconFastForward,IconImage} from 'angular-feather';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FamilyInfoComponent } from './family-info/family-info.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EducationInfoComponent } from './education-info/education-info.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { ExperienceInfoComponent } from './experience-info/experience-info.component';
import { ItesEmployeeBasicinfoComponent } from './ites-employee-basicinfo/ites-employee-basicinfo.component';
const icons = [
  IconSettings,
  IconAlignJustify,
  IconBell,
  IconCircle,
  IconPlusCircle,
  IconEdit,
  IconTrash2,
  IconList,
  IconSkipForward,
  IconMessageSquare,
  IconLogOut,
  IconMaximize,
  IconSearch,
  IconChevronRight,
  IconHome,
  IconRefreshCw,
  IconXCircle,
  IconSkipForward,
  IconUserPlus,
  IconFastForward,
  IconSkipBack,IconAlertTriangle,IconSave,IconTarget,IconLayers,IconAlertCircle,IconTrash2,IconSidebar,IconCreditCard,
  IconImage
];
@NgModule({
  
  declarations: [ EmployeeBasicinfoComponent, EmployeeComponent, FamilyInfoComponent,EmergencyInfoComponent,
    EducationInfoComponent, DashBoardComponent, ExperienceInfoComponent, ItesEmployeeBasicinfoComponent],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    DateInputsModule,
    DialogsModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    UploadModule,
    icons   
  ],
  exports: [
    EmployeeComponent,icons
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    }
  ],
})
export class EmployeeModule { }
