import { Component, OnInit } from '@angular/core';
import { FileRestrictions,SelectEvent } from '@progress/kendo-angular-upload';
import { CommonService } from "../../../shared/services/common.service";
import { DomSanitizer } from '@angular/platform-browser';
import {UserComponent} from '../../user.component';
import { Observable, Observer } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormValidateService } from 'src/app/shared/services/form-validate.service';

@Component({
  selector: 'app-id-card-generation',
  templateUrl: './id-card-generation.component.html',
  styleUrls: ['./id-card-generation.component.scss']
})

export class IdCardGenerationComponent implements OnInit {
  employeeIDCardInfoForm:FormGroup

  employeeInfoUploadpopup:boolean=false;uploadedImage;base64image:any;
  imagename:any;templateName:any;uploadDetails:boolean=false;
  upload:boolean=false;employeeID:any; fileUrl;uploadFailed:boolean=false;downLoadIDCard:boolean=false;
  public uploadRestrictions: FileRestrictions = { allowedExtensions: ['.jpg', '.png'],  };
  public uploadSaveUrl = 'uploadSaveUrl';imagePath:any="./assets/Images/Card.png";show:boolean=false;
  public uploadRemoveUrl = 'uploadRemoveUrl';downLoadIDcard:boolean=false;
  constructor(private commonService_:CommonService,
    private sanitizer: DomSanitizer,
    private UserComponent:UserComponent,
    private formValidateService:FormValidateService) {
    this.employeeID=this.commonService_.retrieve('E_Val');
 }

  ngOnInit() {
  this.UserComponent.organizationBlock=true;
//this.uploadExistingDetails();
  }

  convertBase64ToBlobData(base64Data: string, contentType: string='image/png', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }  
      const byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  imagedownload(){  
  const blobData = this.convertBase64ToBlobData(this.base64image);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blobData, this.imagename);
      } else {
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);        
        const link = document.createElement('a');
        link.href = url;
        link.download = "ID_"+this.imagename;
        link.click();
      }
    
  }

  downLoadFile(data1:any){
    const imageName = data1 + '.' + 'IDCard' + '.jpeg';
// call method that creates a blob from dataUri
    const data = data1;
    const blob = new Blob([data], { type: 'image/jpg' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    //window.open(this.fileUrl);
  }
   
uploadExistingDetails(templateName:string){
 var objtemplateDetails={
    templateName:templateName+".png",
    employeeID:this.employeeID
  }
  this.commonService_.postService('api/Hr/EmployeeIDCardDetailsGET',objtemplateDetails)
  .subscribe((response:any)=>{
    if(response.data!=null){
      this.imagePath='data:image/jpeg;base64,'+response.data;
      this.base64image=response.data; 
      this.downLoadFile(this.imagePath);
      this.downLoadIDCard=true;
      this.imagename=response.text;
    }
    else{
      this.uploadFailed=true;
    }
    
  });
  
}
  uploadEmployeeInfoPopUp(){
    this.createemployeeIDCardInfoForm();   
    this.employeeInfoUploadpopup=true;
    this.uploadFailed=false;
    this.uploadedImage='';
  }

  uploadImageEventHandler(e: SelectEvent): void {
    const that = this;
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();
        
        reader.onloadend = (e) => {
          this.uploadedImage = reader.result;  
           
          (document.getElementById('userProfilePic') as HTMLImageElement).src = this.uploadedImage;     
        }
        reader.readAsDataURL(file.rawFile);
      }
    });
  }
  saveDetailsTemporarily(){
    this.formValidateService.validateAllFormFields_Empty(this.employeeIDCardInfoForm);
if(this.employeeIDCardInfoForm.valid){
  var objIdCardDetails={
    firstName:this.employeeIDCardInfoForm.value.firstName,
     lastName: this.employeeIDCardInfoForm.value.lastName,
     middleName :this.employeeIDCardInfoForm.value.middleName,
     employeeCode :this.employeeIDCardInfoForm.value.employeeCode,
     employeeDepartment :this.employeeIDCardInfoForm.value.employeeDepartment,
     employeeDesignation:this.employeeIDCardInfoForm.value.employeeDesignation,
     bloodGroup :this.employeeIDCardInfoForm.value.bloodGroup,
     emergencyContactNumber:this.employeeIDCardInfoForm.value.emergencyContact,
    employeeID :this.employeeID,
    imagePath :this.uploadedImage,
    templateName:this.templateName
  }
  this.imagename=objIdCardDetails.firstName+objIdCardDetails.lastName;
this.commonService_.postService("api/Hr/GenerateEmployeeIDCard",objIdCardDetails)
.subscribe((response:any)=>{
  if(response.data!=null){
    this.downLoadIDCard=true;
  this.upload=false;
  this.imagePath='data:image/jpeg;base64,'+response.data;
  this.base64image=response.data;
  this.employeeInfoUploadpopup=false;
  }
})
}
else{
 this.formValidateService.validateAllFormFields(this.employeeIDCardInfoForm); 
}
  }

  close(){
    this.employeeInfoUploadpopup=false;
  }

  dataURItoBlob(dataURI): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      observer.next(blob);
      observer.complete();
    });
  }

  Refresh_ID_Card(templateName:string){
    this.templateName=templateName+".png";   
    this.upload=false;   
    this.uploadDetails=true; 
    this.imagePath="./assets/Images/Card.png";
    this.downLoadIDCard=false;
  }
  
  retrieveTemplateName(templateName:string){    
    if(this.downLoadIDCard==false){
    this.templateName=templateName+".png";   
    this.upload=true;   
    this.uploadDetails=true; 
    this.imagePath="./assets/Images/Card.png";
    this.downLoadIDCard=false;
    }
  }


  createemployeeIDCardInfoForm(){

this.employeeIDCardInfoForm=new FormGroup({
  firstName:new FormControl('',[Validators.required]),
  lastName:new FormControl('',[Validators.required]),
  middleName:new FormControl(''),
  emergencyContact:new FormControl('',[Validators.required]),
  bloodGroup:new FormControl('',[Validators.required]),
  idCardImage:new FormControl('',[Validators.required]),
  employeeDepartment:new FormControl('',[Validators.required]),
  employeeCode:new FormControl('',[Validators.required]),
  employeeDesignation:new FormControl('',[Validators.required])
});
this.employeeIDCardInfoForm.reset();
  }
}














