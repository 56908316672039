import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from "../../shared/services/common.service";
import { SignInService } from "../../shared/services/sign-in.service";
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  code: any; AccessToken: any; tokenExpirationTime: number;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private commonService_: CommonService, private SignInService_: SignInService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
    });
    if (this.code != null) {
      this.SignInService_.GET_AccessToken(this.code)
        .subscribe((result: any) => {
          this.AccessToken = result.access_token;
          this.tokenExpirationTime = result.expires_in;
          if (this.AccessToken != null) {
            this.GetUserDetails(this.AccessToken);
          }
        });
    }
    else{
      this.SignInService_.GET_GoogleAuthenticationCode();
    }
  }
  // AuthenticateUser() {
  //   this.SignInService_.GET_GoogleAuthenticationCode();

  // }


  GetUserDetails(AccessToken: any) {
    this.SignInService_.GET_UserDetails(AccessToken)
      .subscribe((result: any) => {
        var userDetails = result;
        if (userDetails != null) {
          var UserLoginDetails = {
            firstName: userDetails.given_name,
            lastName: userDetails.family_name,
            emailAddress: userDetails.email,
            accessToken: this.AccessToken,
            tokenExpirationTime: this.tokenExpirationTime
          }
          this.SignInService_.AUTHENTICATE_User(UserLoginDetails)
            .subscribe((result: any) => {
              var responseCode = result.code;            
              
              if (responseCode == 1) {
                var userID = result.data.userID;
                var accessLevel=result.data.accessLevel;
                this.commonService_.store('a_L',accessLevel);
                this.commonService_.store('r_L',result.data.roleName);
                this.commonService_.store('a_L',result.data.accessLevel);
                this.commonService_.store('u_N',result.data.firstName);                
                userDetails = { "username": UserLoginDetails.firstName, "userID": userID }
                this.commonService_.authenticateUser(userDetails)
                  .subscribe((res: any) => {
                    var accessToken = res.access_token
                    var refreshToken = res.refresh_token
                    if (accessToken != null) {                     
                      this.commonService_.store('AccessToken', accessToken);
                      this.commonService_.store('RefreshToken', refreshToken);  
                      this.commonService_.store("E_Val",0);                   
                     if(accessLevel!=undefined&&accessLevel!=0){
                      if(accessLevel==1||accessLevel==2){
                        this.router.navigateByUrl('user/employee/dash-board');
                      }
                      else if(accessLevel==3){
                        this.router.navigateByUrl('user/hr');
                      }
                      else if(accessLevel==4){
                        this.router.navigateByUrl('user/management');
                      }
                     }
                     else{
                      this.router.navigateByUrl('/not-activated');
                     }
                      
                    }

                  },
                    (error => {
                      if (error.status == 400 && error.error.error == "invalid_grant") {
                        this.router.navigateByUrl('sign-up');
                      }
                    })
                  );

              }
              else {
                this.router.navigateByUrl('sign-up');
              }
            });
        }
      });
  }


}
