import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private storage: any;
  constructor(private notificationService: NotificationService, private httpClient: HttpClient, private router: Router) {
    this.storage = localStorage;
  }

  //Oauth Services
  authenticateUser(userDetails) {
    var userData = "username=" + encodeURIComponent(userDetails.username) + "&password=" + encodeURIComponent(userDetails.userID) + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.httpClient.post(environment.userOauthUri, userData, { headers: reqHeader })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.errorHandler)
      );
  }

//  public refreshToken(): Observable<any> {
//     var response = new Subject<any>();
//     var userData = "grant_type=refresh_token&client_id=CLIENT_ID&client_secret=CLIENT_SECRET&refresh_token=" + this.retrieve('RefreshToken');
//     var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
//     this.httpClient.post(environment.userOauthUri, userData, { headers: reqHeader })
//       .subscribe((res: any) => {
//         if (res.access_token != null) {
//           this.store('AccessToken', res.access_token);
//           this.store('RefreshToken', res.refresh_token);
//         }
//         response.next(res);
//       },
//         error => {
//           if (error.status == 400) {
//             this.router.navigateByUrl('/sign-out');
//           }
//         });
//         return response.asObservable();
//   }


  public postService(url: any, requestObject: any): Observable<any> {
    var response = new Subject<any>();
    if (url != 'api/User/UserRegistration' && this.retrieve('AccessToken') == undefined) {
      this.router.navigateByUrl('/sign-out');
    }
    else {
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.retrieve('AccessToken')}`);
      this.httpClient.post(environment.appUrl + url, requestObject, { headers })
        .subscribe((result: any) => {
          let responseCode = result.code;
          if (responseCode == 1||responseCode == -7) {
            response.next(result);
          }
          else if (responseCode == -8) {
            this.displayMessage('Data already Exists..!!', 'error');
          }
          else if (responseCode == -10) {
            this.displayMessage('Unable to process your request..!!', 'error');
          }
          else if (responseCode == -6) {
            this.displayMessage('Invalid file type..Please upload valid image..!!', 'error');
          } 
        },
          (error => {
            if (error.status == 401) {
              var userData = "grant_type=refresh_token&client_id=CLIENT_ID&client_secret=CLIENT_SECRET&refresh_token=" + this.retrieve('RefreshToken');
              var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
              this.httpClient.post(environment.userOauthUri, userData, { headers: reqHeader })
                .subscribe((res: any) => {
                  if (res.access_token != null) {
                    this.store('AccessToken', res.access_token);
                    this.store('RefreshToken', res.refresh_token);          
                    headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${ res.access_token}`);          
                      this.httpClient.post(environment.appUrl + url, requestObject, { headers })
                        .subscribe((result: any) => {
                          let responseCode = result.code;
                          if (responseCode == 1||responseCode == -7) {
                            response.next(result);
                          }
                          else if (responseCode == -8) {
                            this.displayMessage('Data already Exists..!!', 'error');
                          }
                          else if (responseCode == -10) {
                            this.displayMessage('Unable to process your request..!!', 'error');
                          }
                          else if (responseCode == -6) {
                            this.displayMessage('Invalid file type..Please upload valid image..!!', 'error');
                          } 
                        })                
                }
                },
                  error => {
                    if (error.status == 400) {
                      this.router.navigateByUrl('/sign-out');
                    }
                  });
            }
          })
          
        );
    }    
    return response.asObservable();
  }

  public getService(url: any, parameters: any): Observable<any> {
    var apiUrl: any;let responseCode;
    var response = new Subject<any>();
    apiUrl = parameters == '' ? environment.appUrl + url : environment.appUrl + url + "?" + parameters;
    if (url != 'api/Employee/AccessLevels_GET' && this.retrieve('AccessToken') == undefined) {
      this.router.navigateByUrl('/sign-out');
    } else {
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.retrieve('AccessToken')}`);
      this.httpClient.get(apiUrl, { headers })
        .subscribe((result: any) => {
          responseCode = result.code;
          if (responseCode == 1 || responseCode==-7) {
            response.next(result);
          }
          else if (responseCode == -6) {
            this.displayMessage('Invalid file type..Please upload valid image..!!', 'error');
          }        
          else if (responseCode == -8) {
            this.displayMessage('Data already Exists..!!', 'error');
          }
          else if (responseCode == -10) {
            this.displayMessage('Unable to process your request..!!', 'error');
          } else if(responseCode==-9){
            this.displayMessage("There is no  Details... !","warning"); 
          }
        },
          (error => {
            if (error.status == 401) {
              var userData = "grant_type=refresh_token&client_id=CLIENT_ID&client_secret=CLIENT_SECRET&refresh_token=" + this.retrieve('RefreshToken');
              var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
              this.httpClient.post(environment.userOauthUri, userData, { headers: reqHeader })
                .subscribe((res: any) => {
                  if (res.access_token != null) {
                    this.store('AccessToken', res.access_token);
                    this.store('RefreshToken', res.refresh_token);  
                     headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${ res.access_token}`);                  
                      this.httpClient.get(apiUrl, { headers })
                        .subscribe((result: any) => {
                          responseCode = result.code;
                          if (responseCode == 1 || responseCode==-7) {
                            response.next(result);
                          }
                          else if (responseCode == -6) {
                            this.displayMessage('Invalid file type..Please upload valid image..!!', 'error');
                          }        
                          else if (responseCode == -8) {
                            this.displayMessage('Data already Exists..!!', 'error');
                          }
                          else if (responseCode == -10) {
                            this.displayMessage('Unable to process your request..!!', 'error');
                          }
                        })
                  
                }
                },
                  error => {
                    if (error.status == 400) {
                      this.router.navigateByUrl('/sign-out');
                    }
                  });
            }
          })
        );
    }
    return response.asObservable();
  }

  public Data(data: any) {
    return data
  }
  public displayMessage(content, type): void {

    this.notificationService.show({
      content: content,
      animation: { type: 'slide', duration: 1000 },
      type: { style: type, icon: true },
      position: { horizontal: 'right', vertical: 'top' },
      hideAfter: 5000,
    });
  }

  errorHandler(error: Response) {
    return throwError(error);
  }


  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }


  public retrieve(key: string): any {
    const item = this.storage.getItem(key);
    if (item && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(key));
    }
    return;
  }
  public remove(key: string) {
    this.storage.removeItem(key);
  }

  public clear() {
    this.storage.clear();
  }

  //Common method for date conversion

  DateConversion(date) {
    let selectedDate: Date = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate());
    return selectedDate;
  }
  DateTimeConversion(date){
    let selectedDate: Date = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(),new Date(date).getHours());
    return selectedDate;
  }
  handleInput(e) {
    var k = e.charCode;
    return ((k >= 48 && k <= 57));
  };
  handleInputDecimal(e) {
    var k = e.charCode;    
    if(e.srcElement.value.length==2){
      return (k==46);
    }else{
      return ((k >= 48 && k <= 57));
    }    
  };
  handleInputAlpha(e){
    var key = e.charCode;
    return ((key >= 65 && key <= 90) || (key >= 97 && key <= 122) || key == 43||key==45);
  }
  // handleInputSpace(e){
    
  //  var k = e.charCode;   
  //   if(k==32){
  //     if(e.srcElement.value.trim().length!==0){
  //       return (k==32);
  //     }else{
  //       e.srcElement.value="";
  //     }
  //   }                                                                                                         
  // }

  handleInputCalendar(e) {
    var k = e.keyCode;
    if (!(k >= 48 && k <= 57) || !(k >= 96 && k <= 105))
      return false;
    else
      return true;
  };
}










