import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { UserComponent } from "../../user.component";

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  public gridData:GridDataResult;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public searchtext:string;
  

  constructor(private CommonService: CommonService, private Router: Router,private UserComponent_:UserComponent) {
   
   }

  ngOnInit() {
    this.CommonService.store("E_Val",0);
    this.UserComponent_.organizationBlock=false;
    this.EmployeeList("");
  }


  //To retrieve employee list
  EmployeeList(searchtext) {    
    this.CommonService.getService("api/Hr/EmployeeListGET", "serachText="+searchtext).subscribe((result: any) => {
      if (result.code == 1) {
        var employeeList = [];
        employeeList = result.data; 
       
  if(employeeList!=null && employeeList.length>0){
          this.items = employeeList;
          this.items.length > 10? this.pageable = true : this.pageable = false;
          this.loadItems(1);   
        } 
        else{
          this.items=[];
          this.loadItems(1);
        }           
      }
      else if(result.code==0){
        this.Router.navigateByUrl('sign-in');
      }
      else{
        this.CommonService.displayMessage("!oops There was an error please try after some time....","error"); 
      }
     
    });
  }
// searching 

Search(){
  this.EmployeeList(this.searchtext.trim());
}

  //paging
  private loadItems(type:any): void {
    if(type==1)
    this.skip=0;
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }
  //add new employee details
  Add(){
    this.CommonService.store("E_Val",-1);
    this.UserComponent_.organizationBlock=false;
    this.Router.navigateByUrl('user/employee/employee-basicinfo');
  }

  //Navigation

  Edit(ID) {
    this.CommonService.store("E_Val", ID);
    this.UserComponent_.refreshMenu();
    this.Router.navigateByUrl('user/employee/employee-basicinfo');
    this.UserComponent_.organizationBlock=true;
  }


}
