import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from "../../../environments/environment";
import { emailFormat } from '../../shared/constants/constants';
import { FormValidateService } from '../../shared/services/form-validate.service';
import { CommonService } from '../../shared/services/common.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public signUpForm: FormGroup;
  public accessLevel: Array<{ accessType: string, accessLevelID: number, accessLevelStatus: number }> = [];  
  constructor(private http: Http, private router: Router, private fb: FormBuilder, private formValidateService: FormValidateService, private CommonService: CommonService) {
    this.signUpForm = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      emailAddress: ['', [Validators.required, Validators.pattern(emailFormat)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      accessLevelID: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.accessLevelsGET();
  }
  signUp() {
    if (this.signUpForm.valid) {
      var objSignup={
        firstName:this.signUpForm.value.firstName,
        lastName:this.signUpForm.value.lastName,
        emailAddress:this.signUpForm.value.emailAddress,
        phoneNumber:this.signUpForm.value.phoneNumber,
        accessLevelID:this.signUpForm.value.accessLevelID.accessLevelID
      }
      this.CommonService.postService("api/User/UserRegistration", objSignup).subscribe((result: any) => {
        if (result.code == 1) {        
          this.CommonService.displayMessage("Registered Successfully..!!","success"); 
          this.navigation();
        }
        else if (result.code == -8) {
          this.CommonService.displayMessage("Already Exists....!!!", "error");
        }
        else {
          this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
      });
    }
    else {
      this.formValidateService.validateAllFormFields(this.signUpForm);
    }
  }

    //#region AccessLevelGET
    accessLevelsGET() {
      var accessLevelList = [];
      this.CommonService.getService("api/Employee/AccessLevels_GET", '')
        .subscribe((response: any) => {
          if (response.code == 1) {
            var data = response.data;
            data.forEach(element => {
              if(element.accessType=='IT' || element.accessType=='ITES')
              accessLevelList.push({ "accessLevelID": element.accessLevelID, "accessType": element.accessType +' - (' + element.accessDescription +' )', "accessLevelStatus": element.accessLevelStatus })
            });
            this.accessLevel = accessLevelList;           
          }          
          else{
            this.CommonService.displayMessage("!oops There was an error please try after some time....","error"); 
          }
        })
    }

    public itemDisabled(itemArgs: { dataItem: string, index: number }) {
      return itemArgs.dataItem["accessLevelStatus"] == 2;
    }

    navigation(){
      setTimeout(()=> {
        this.router.navigateByUrl('/sign-in');                 
      }, 5000);    
    }
}
