import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserRoutingModule} from './user-routing.module';
import { UserComponent } from '../user/user.component';
import { EmployeeModule } from './employe/employee/employee.module';

import { IconSettings, IconAlignJustify, IconBell, IconMessageSquare, IconList,IconLogOut,IconEdit,IconTrash2, IconMaximize,IconLock,IconDollarSign,IconArchive,
  IconSearch,IconChevronRight,IconHome,IconSkipForward,IconCircle,IconPlusCircle, IconRefreshCw, IconUser, IconXCircle, IconSkipBack,IconUpload,IconCheckCircle,
  IconAlertTriangle, IconSave, IconTarget, IconLayers, IconAlertCircle,IconSidebar,IconCreditCard,IconTag,IconBriefcase,IconBookOpen,IconPhoneCall,IconCalendar,IconImage, IconHeadphones} from 'angular-feather';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { HrModule } from './hr/hr.module';
import { ManagementModule } from './management/management.module';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ApproveRejectLeaveComponent } from './approve-reject-leave/approve-reject-leave.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CalendarInfoComponent } from './calendar-info/calendar-info.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';




const icons = [
  IconSettings,
  IconAlignJustify,
  IconBell,
  IconCircle,
  IconPlusCircle,
  IconEdit,
  IconTrash2,
  IconList,
  IconSkipForward,
  IconMessageSquare,
  IconLogOut,
  IconMaximize,
  IconSearch,
  IconChevronRight,
  IconHome,
  IconRefreshCw,
  IconXCircle,
  IconSkipForward,
  IconTag,
  IconCalendar,
  IconUpload,
  IconLock,
  IconUser,
  IconDollarSign,
  IconCheckCircle,
  IconArchive,
  IconSkipBack,IconAlertTriangle,IconSave,IconTarget,IconLayers,IconAlertCircle,IconTrash2,IconSidebar,IconCreditCard,IconBriefcase,IconBookOpen,IconPhoneCall,
  IconImage,IconHeadphones
];

@NgModule({
  declarations: [UserComponent, LeaveRequestComponent, ApproveRejectLeaveComponent, CalendarInfoComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    EmployeeModule,
    FormsModule,
    SchedulerModule,
    ReactiveFormsModule,
    InputsModule,
    GridModule,
    DropDownsModule,
    ButtonsModule,
    DateInputsModule,
    HrModule,
    DialogsModule,
    ManagementModule,
    GridModule,
    icons
    
  ],
  exports: [
    UserComponent,icons
  ]
})
export class UserModule { }
