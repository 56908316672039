
import { Injectable } from '@angular/core';
import { RequestOptions, Headers, } from '@angular/http';
import { environment } from "../../../environments/environment";
import { CommonService } from "../../shared/services/common.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  constructor(
    private http: HttpClient, private commonService_: CommonService) { }

    GET_GoogleAuthenticationCode() {
      var url = environment.googleOauthUri + "?redirect_uri=" + environment.redirect_uri + "&response_type=code&client_id=" +environment.clientID + "&scope="+environment.scope+"&access_type=offline";
      window.location.href = url;
    }

    GET_AccessToken(code) {
     
      let  parameters = "code=" + code + "&client_id=" + environment.clientID +
          "&client_secret=" + environment.clientSecret + "&redirect_uri="
          + environment.redirect_uri + "&grant_type=authorization_code";
      let headers = new Headers({ 'Content-Type': "application/x-www-form-urlencoded" });
      let options = new RequestOptions({ headers: headers });
      return this.http.post(environment.accessToken_uri + parameters, options)
      .pipe(
        map(res => res),
        catchError(this.commonService_.errorHandler)
    );
    }


    GET_UserDetails(AccessToken) {
      var url = environment.getGoogleUserDetailsUri + AccessToken;
      let headers = new HttpHeaders().set('Content-Type', `application/x-www-form-urlencoded`);
     return this.http.get(url, {headers})
     .pipe(
      map(res => res),
      catchError(this.commonService_.errorHandler)
  );
    }


    AUTHENTICATE_User(UserLoginDetails:any){
      let headers = new HttpHeaders().set( 'Content-Type', 'application/json' );
      //let options = new RequestOptions({ headers: headers });
      return this.http.post(environment.appUrl+ "api/User/UserSignIn", UserLoginDetails,{headers})
      .pipe(
        map(res => res),
        catchError(this.commonService_.errorHandler)
      );
    }

    googleCalendar_GET(){ 
      var url = environment.googleOauthUri + "?redirect_uri=" + environment.Calendar_redirect_uri + "&response_type=code&client_id=" +environment.clientID + "&scope="+environment.calendarScope+"&access_type=offline" +"&prompt=consent";
      window.location.href = url;
    }

    GET_CalendarAccessToken(code) {
      let  parameters = "code=" + code + "&client_id=" + environment.clientID +
          "&client_secret=" + environment.clientSecret + "&redirect_uri="
          + environment.Calendar_redirect_uri + "&grant_type=authorization_code";
      let headers = new Headers({ 'Content-Type': "application/x-www-form-urlencoded" });
      let options = new RequestOptions({ headers: headers });
      return this.http.post(environment.accessToken_uri + parameters, options)
      .pipe(
        map(res => res),
        catchError(this.commonService_.errorHandler)
    );
    } 
}
