import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeComponent } from './employee.component';
import { EmployeeBasicinfoComponent } from './employee-basicinfo/employee-basicinfo.component';
import { FamilyInfoComponent } from './family-info/family-info.component';
import { EmergencyInfoComponent } from './emergency-info/emergency-info.component';
import { EducationInfoComponent } from './education-info/education-info.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { ExperienceInfoComponent } from './experience-info/experience-info.component';
import { ItesEmployeeBasicinfoComponent } from './ites-employee-basicinfo/ites-employee-basicinfo.component';

const routes: Routes = [
  {


  path: '', component: EmployeeComponent,
        children: [
          {
            path:'' ,pathMatch: 'full', component:EmployeeBasicinfoComponent,data:{title:'Employee Basic Information'}
        },    
            {
                path:'employee-basicinfo' , component:EmployeeBasicinfoComponent,data:{title:'Employee Basic Information'}
            },
            {
              path:'family-info',component:FamilyInfoComponent,data:{title:'Family Information'}
            },
            {
              path:'emergency-info' , component:EmergencyInfoComponent,data:{title:'Emergency Contact Information'}
            },            
            {
              path:'educational-info' , component:EducationInfoComponent,data:{title:'Educational Information'}
            },            
            {
              path:'dash-board',component:DashBoardComponent,data:{title:'Dashboard'}
            },
            {
              path:'experience-info',component:ExperienceInfoComponent,data:{title:'Experience Information'}
            },
            {
              path:'ites-employeeinfo',component:ItesEmployeeBasicinfoComponent,data:{title:'ITES Basic Information'}
            }
        ]       
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
