import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from "../../../shared/services/common.service";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import {Router} from '@angular/router';
import { FormValidateService } from 'src/app/shared/services/form-validate.service';


@Component({
  selector: 'app-project-allocations',
  templateUrl: './project-allocations.component.html',
  styleUrls: ['./project-allocations.component.scss']
})
export class ProjectAllocationsComponent implements OnInit {
  projectInfoADDForm: FormGroup; projectInfoUPDATEForm: FormGroup; projectAllocationForm: FormGroup;

  public gridData: GridDataResult; objTeamAllocation: any = { "projectID": 0, "teamIDlist": '' }; teamIDList: any = [];
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public toMaxDate:Date;
  projectStatusList: Array<{ "status": string, "value": number }> = [{ status: "Active", value: 1 }, { status: "Inactive", value: 2 }, { status: "Completed", value: 3 }];
  teamsList: Array<{ "teamID": number, "teamName": string }> = []; teamsFilterList: Array<{ "teamID": number, "teamName": string }> = [];
  projectInfoAddpopup: boolean = false; projectInfoUpdatePopUP: boolean = false; projectAllocationPopUP: boolean = false;

  constructor(private commonService_: CommonService,private Router:Router,private formValidateService:FormValidateService) { }

  ngOnInit() {
    this.projectGET();
  }

  projectInfoADDPopUp() {
    this.createProjectADDForm();
    this.toMaxDate=this.commonService_.DateConversion(new Date());
    this.projectInfoAddpopup = true;
  }

  ProjectADD() {
    this.formValidateService.validateAllFormFields_Empty(this.projectInfoADDForm);
    if (this.projectInfoADDForm.valid) {
      this.commonService_.postService("api/Employee/ProjectADD", this.projectInfoADDForm.value)
        .subscribe((response: any) => {
          if (response.code == 1) {
            this.projectGET();
          }
        });
      this.projectInfoAddpopup = false;
    }
    else{
this.formValidateService.validateAllFormFields(this.projectInfoADDForm)
    }

  }

  projectGET() {
    this.commonService_.getService("api/Employee/ProjectsGET", '')
      .subscribe((response: any) => {
        if (response.code == 1) {
          var projectsList = [], resultData;
          resultData = response.data;
          resultData.forEach(element => {
            var status = this.projectStatusList.filter(item => item.value === element.projectStatus);
            var projectDetails = {
              projectName: element.projectName,
              projectDescription: element.projectDescription,
              projectStartDate: element.projectStartDate,
              projectStatus: status[0].status,
              projectStatusID: element.projectStatus,
              projectID: element.projectID,
              projectTeamID: element.projectTeamID
            }
            projectsList.push(projectDetails);
          });
         
          if (projectsList!=null){
            this.items = projectsList;
            this.items.length > 10? this.pageable = true : this.pageable = false;
            this.loadItems();
          }
          else{
            this.items=[];
            this.loadItems();
          }
        }
        else if(response.code==0){
          this.Router.navigateByUrl('sign-in');
        }
        else{
          this.commonService_.displayMessage("!oops There was an error please try after some time....","error"); 
        }     
      });
  }

   //paging
   private loadItems(): void {
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  TeamsGET() {
    var teams = [], team = [];
    this.commonService_.getService("api/Hr/TeamsGET", '')
      .subscribe((response: any) => {
        if (response.code == 1) {
          let data = response.data;
          data.forEach(team => {
            teams.push({ "teamID": team.teamID, "teamName": team.teamName })
          });
          this.teamsList = teams;
          this.teamsFilterList = teams;
          this.teamIDList.forEach(element => {
            let teamDetails = this.teamsList.filter(item => item.teamID === element)
            if(teamDetails!=undefined && teamDetails.length>0)
            team.push({ "teamID": teamDetails[0].teamID, "teamName": teamDetails[0].teamName })
          });
          this.projectAllocationForm.controls.teamID.setValue(team);
        }
      });
  }

  projectAllocationPopup(dataItem: any) {
    this.createprojectAllocationFOrm();
    this.objTeamAllocation.projectID = dataItem.projectID;
    this.teamIDList = [];
    for(var index=0;index<dataItem.projectTeamID.toString().split(',').length;index++){
      this.teamIDList.push(dataItem.projectTeamID.toString().split(',')[index].toString());    

     
    }
    this.TeamsGET();
    this.projectAllocationPopUP = true;
    //this.projectAllocationForm.controls.teamID.setValue({"teamID": dataItem.teamID, "teamName": dataItem.teamName})
  }

  // public valueChange(value: any) {
  //   var teamIDs = [];
  //   value.forEach(team => {
  //     teamIDs.push(team.teamID);
  //   });
  //   this.objTeamAllocation.teamIDList = "" + teamIDs.join(",");
  // }

  public filterChange(filter: any) {
    this.teamsFilterList = this.teamsList.filter((s) => s.teamName.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }
  
  projectAllocation_Team() {
if(this.projectAllocationForm.valid){
  this.objTeamAllocation.teamIDList="";
  this.projectAllocationForm.value.teamID.forEach(team => {    
    this.objTeamAllocation.teamIDList+=team.teamID+",";   
  });

  
  this.commonService_.postService("api/Employee/ProjectAllocationTEAM", this.objTeamAllocation)
  .subscribe((response: any) => {
    if (response.code == 1) {
      this.projectAllocationPopUP = false;
      this.projectGET();
      this.commonService_.displayMessage("Teams Allocated successfully..", 'success');
    }
    else {
      this.commonService_.displayMessage("Uanble to process your request..", 'error');
    }
  });
}
else{
  this.formValidateService.validateAllFormFields(this.projectAllocationForm);
}   
    
  }


  updateProjectPopUp(projectDetails: any) {
    var status = this.projectStatusList.filter(item => item.status === projectDetails.projectStatus);
    this.createProjectUpdateForm();
    this.projectInfoUPDATEForm.setValue({
      projectID: projectDetails.projectID,
      projectDescription: projectDetails.projectDescription,
      projectStatus: { status: projectDetails.projectStatus, value: status[0].value }
    });
    this.projectInfoUpdatePopUP = true;
  }


  projectDetailsUpdate() {
    var status = this.projectStatusList.filter(item => item.status === this.projectInfoUPDATEForm.value.projectStatus.status);
    var projectDetails = {
      projectID: this.projectInfoUPDATEForm.value.projectID,
      projectDescription: this.projectInfoUPDATEForm.value.projectDescription,
      projectStatus: status[0].value
    }
    this.commonService_.postService("api/Employee/ProjectDetailsUpdate", projectDetails)
      .subscribe((response: any) => {
        if (response.code == 1) {
          this.projectGET();
        }
        else {
          this.commonService_.displayMessage("Uanble to proccess your request", 'error');
        }
      })
    this.projectInfoUpdatePopUP = false;
  }
  createProjectADDForm() {
    this.projectInfoADDForm = new FormGroup({
      projectName: new FormControl('', [Validators.required]),
      projectDescription: new FormControl('', [Validators.required]),
      projectStartDate: new FormControl('', [Validators.required])
    });
    this.projectInfoADDForm.reset();
  }

  createProjectUpdateForm() {
    this.projectInfoUPDATEForm = new FormGroup({
      projectID: new FormControl(''),
      projectDescription: new FormControl('', [Validators.required]),
      projectStatus: new FormControl('', [Validators.required,Validators.maxLength(50)])
    });
    this.projectInfoUPDATEForm.reset();
  }

  createprojectAllocationFOrm() {
    this.projectAllocationForm = new FormGroup({
      teamID: new FormControl([], [Validators.required])
    });
    this.projectAllocationForm.reset();
  }
  close() {
    this.projectInfoAddpopup = false;
    this.projectInfoUpdatePopUP = false;
    this.projectAllocationPopUP = false;
  }
}
