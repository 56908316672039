import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SignInService } from '../../shared/services/sign-in.service';
import { CommonService } from '../../shared/services/common.service';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { EventsOutsideAngularDirective } from '@progress/kendo-angular-common';


@Component({
  selector: 'app-calendar-info',
  templateUrl: './calendar-info.component.html',
  styleUrls: ['./calendar-info.component.scss']
})
export class CalendarInfoComponent implements OnInit {

  public code: any; AccessToken: any; tokenExpirationTime: number; RefreshToken: any; confirmationPopup: boolean; accessLevel: any;
  constructor(private SignInService: SignInService, private ActivatedRoute: ActivatedRoute, private CommonService: CommonService, private Router: Router) { }
  public displayDate;
  public selectedDate: Date; eventsData: any = []; public events: SchedulerEvent[];


  ngOnInit() {
    this.accessLevel = this.CommonService.retrieve('a_L');
    if (this.accessLevel == 3 || this.accessLevel == 4)
      this.Google_Cal_details_GET();
    this.Google_Cal_Dev_Events_GET();
  }
  //To retrieve google calendar events

  Google_Cal_Dev_Events_GET() {
    this.CommonService.getService("api/Hr/Google_Cal_Dev_Events_GET", "").subscribe((result: any) => {
      if (result.code == 1) {
        this.displayDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.selectedDate = this.displayDate;
        var eventsDetails = [];
        eventsDetails = result.data;
        this.eventsSet(eventsDetails);
        this.events = this.eventsData;
      }
    })
  }

  eventsSet(eventsDetails) {
    const parseAdjust = (eventDate: string): Date => {
      const date = new Date(eventDate);
      date.setFullYear(new Date().getFullYear());
      return date;
    };

    const randomInt = (min, max): number => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    this.eventsData = eventsDetails.map(dataItem => (
      <SchedulerEvent>{
        id: dataItem.TaskID,
        start: parseAdjust(dataItem.Start),
        startTimezone: dataItem.startTimezone,
        end: parseAdjust(dataItem.End),
        endTimezone: dataItem.endTimezone,
        isAllDay: dataItem.IsAllDay,
        title: dataItem.Title,
        description: dataItem.Description,
        recurrenceRule: dataItem.RecurrenceRule,
        recurrenceId: dataItem.RecurrenceID,
        recurrenceException: dataItem.RecurrenceException,

        roomId: dataItem.RoomID,
        ownerID: dataItem.OwnerID
      }
    ));

    // var sampleDataWithResources = eventsDetails.map(dataItem => (
    //   <SchedulerEvent> {
    //       id: dataItem.TaskID,
    //       start: parseAdjust(dataItem.Start),
    //       startTimezone: dataItem.startTimezone,
    //       end: parseAdjust(dataItem.End),
    //       endTimezone: dataItem.endTimezone,
    //       isAllDay: dataItem.IsAllDay,
    //       title: dataItem.Title,
    //       description: dataItem.Description,
    //       recurrenceRule: dataItem.RecurrenceRule,
    //       recurrenceId: dataItem.RecurrenceID,
    //       recurrenceException: dataItem.RecurrenceException,
    //       roomId: randomInt(1, 2),
    //       attendees: [randomInt(1, 3)]
    //   }
    // ));

    // var sampleDataWithCustomSchema = eventsDetails.map(dataItem => (
    //   {
    //       ...dataItem,
    //       Start: parseAdjust(dataItem.Start),
    //       End: parseAdjust(dataItem.End)
    //   }
    // ));

  }

  //To connect google for calendar
  Google_Cal_Connect() {
    this.ActivatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
    });
    if (this.code != null) {
      this.SignInService.GET_CalendarAccessToken(this.code)
        .subscribe((result: any) => {
          if (result.access_token != null) {
            var objTokenDetals = {
              accessToken: result.access_token,
              expiredOn: result.expires_in,
              refreshToken: result.refresh_token
            }
            this.Google_Cal_Dev_Details_Save(objTokenDetals);
          }
        });
    }
    else {
      this.SignInService.googleCalendar_GET();
    }
  }

  //to retrieve google cal details from db
  Google_Cal_details_GET() {
    this.CommonService.getService("api/Hr/Google_Cal_details_GET", "").subscribe((result: any) => {
      if (result.code == -7) {
        this.Google_Cal_Connect();
      }
      else if (result.code == -2) {
        this.confirmationPopup = true;
      }
    });
  }

  //To save the google cal token details in db
  Google_Cal_Dev_Details_Save(objTokenDetals) {
    this.CommonService.postService("api/Hr/Google_Cal_Dev_Details_Save", objTokenDetals).subscribe((result: any) => {
      if (result.code == 1) {
        this.CommonService.displayMessage("Added Successfully...!!", "success");
        this.Router.navigateByUrl('user/events');
        //window.location.href = environment.Calendar_redirect_uri;
      }
      else if (result.code == -7) {
        this.confirmationPopup = true;
        this.Router.navigateByUrl('user/events');
      }
    });
  }

  //TO sync events
  Sync() {
    this.CommonService.getService("api/Hr/OnDemand_Cal_Dev_Job_ADD", "").subscribe((result: any) => {
      if (result.code == 1) {
        this.CommonService.displayMessage("Added Successfully...", "success");
      }
    });
  }

  //Close popup
  close() {
    this.confirmationPopup = false;
  }

}
