import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { UserComponent } from "../../user.component";

@Component({
  selector: 'app-employee-attendance',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.scss']
})
export class EmployeeAttendanceComponent implements OnInit {

  employeeNameList: Array<{ text: string, value: number }> = [];
  datefilterList: Array<{ text: string, value: number }> = [];

  public gridData: GridDataResult;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  empSelected;
  dateslected;
  toMaxDate: Date;
  custom_selected: boolean;
  fromdate:Date;
  todate:Date;
  viewimage:boolean=false;
  imagePath:any="./assets/Images/no-image.png"

  constructor(
    private CommonService: CommonService,
    private Router: Router,
    private UserComponent_: UserComponent) {

  }

  ngOnInit() {
    this.custom_selected = false;
    this.toMaxDate = this.CommonService.DateConversion(new Date());
    this.fromdate=new Date();
      this.todate=new Date();
    this.CommonService.store("E_Val", 0);
    this.UserComponent_.organizationBlock = false;
    this.datefilterList.push({ text: "All", value: 0 });
    this.datefilterList.push({ text: "Today", value: 1 });
    this.datefilterList.push({ text: "Yesterday", value: 2 });
    this.datefilterList.push({ text: "Last week", value: 3 });
    this.datefilterList.push({ text: "Last Month", value: 4 });
    this.datefilterList.push({ text: "Custom", value: 5 });
    this.dateslected = { text: "All", value: "0" }
    this.EmployeeList();
    this.EmployeeAtt_List(0, 0);
  }


  //To retrieve employee For Filter Drop down
  EmployeeList() {
    this.CommonService.getService("api/Hr/EmployeeListGET", "").subscribe((result: any) => {

      if (result.code == 1) {
        var employeeList = [];
        employeeList = result.data;

        if (employeeList != null && employeeList.length > 0) {

          this.employeeNameList.push({ text: "All", value: 0 });

          for (var index = 0; index < employeeList.length; index++) {
            let name = employeeList[index].firstName;
            let id = employeeList[index].employeeID;
            this.employeeNameList.push({ text: name, value: id });
            this.empSelected = { text: "All", value: "0" };
          }
        }
        else {
          this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
      }

      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }

    });
  }
  //To retrieve employee attendancelist
  EmployeeAtt_List(empid, option) {
    this.items = [];
    this.loadItems(1);
    this.CommonService.getService("api/Hr/EmployeeAttendanceListGET", "empid=" + empid + "&option=" + option).subscribe((result: any) => {

      if (result.code == 1) {
        var employeeList = [];
        employeeList = result.data;

        if (employeeList != null && employeeList.length > 0) {
          this.items = employeeList;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(1);
        }
        else {
          this.items = [];
          this.loadItems(1);
        }
      }

      else if (result.code == 0) {
        this.Router.navigateByUrl('sign-in');
      }
      else {
        this.CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }

    });
  }


  //paging
  private loadItems(type: any): void {
    if (type == 1)
      this.skip = 0;
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }
  view(imagebinary) {
    this.viewimage=true;
    console.log(imagebinary);
    if(imagebinary!="noimage"){
    this.imagePath='data:image/jpeg;base64,'+imagebinary;
    }else{
      this.imagePath="./assets/Images/no-image.png";
    }
    //this.CommonService.displayMessage("this feature available soon....", "warning");
  }

  DatefilterChange(filter) {
    if (filter.value == 5) {
      this.custom_selected = true;
    } else {
      this.fromdate=new Date();
      this.todate=new Date();
      this.custom_selected = false;
      this.EmployeeAtt_List(this.empSelected.value, filter.value);
    }
  }
  employeeNameChange(name) {
    this.EmployeeAtt_List(name.value, this.dateslected.value);
  }
  search() {
    this.EmployeeAtt_List(this.empSelected.value, this.fromdate.toDateString()+","+this.todate.toDateString());
  }
  close(){
    this.viewimage=false;
  }
}
