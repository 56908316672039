import { Component, OnInit } from '@angular/core';
import { FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormValidateService } from 'src/app/shared/services/form-validate.service';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from "../../../shared/services/common.service";
import { UserComponent } from '../../user.component';
import { environment } from "../../../../environments/environment";


@Component({
  selector: 'app-document-info',
  templateUrl: './document-info.component.html',
  styleUrls: ['./document-info.component.scss']
})
export class DocumentInfoComponent implements OnInit {
  documentInfoADDForm: FormGroup;


  public fileData: FormData;
  public gridView: GridDataResult;
  public pageSize = 10; apiMethodUrl: string;
  public skip = 0; confirm: boolean = false; employeeID: number;
  private items: any[]; pageable: boolean = false; fileInfo: any = { employeeID: 0, fileID: 0 };
  public document: Array<{ "fileID": number, "fileName": string, "fileType": string }>
  documentInfoAddpopup: boolean = false; uploadedFile: any; fileUploadURL: any;
  public uploadRestrictions: FileRestrictions = { allowedExtensions: ['.pdf', '.doc', '.csv', '.txt', '.png', '.jpg'], maxFileSize: 614400 };
  public uploadSaveUrl = environment.appUrl + "api/Hr/employeeDocumentUpload";
  public uploadRemoveUrl = 'uploadRemoveUrl';
  constructor(private http: HttpClient,
    private FormValidateService_: FormValidateService,
    private router: Router,
    private commonService_: CommonService,
    private UserComponent: UserComponent) {
    this.employeeID = this.commonService_.retrieve('E_Val');
  }

  ngOnInit() {
    this.apiMethodUrl = environment.appUrl + "api/Hr/employeeDocumentUpload";
    this.UserComponent.organizationBlock = true;
    this.CreateAddDocumentsForm();
    this.documentDetailsGET();
  }

  uploadFileURL() {
    this.fileUploadURL = environment.appUrl + "api/Hr/Doc?employeeID=" + this.employeeID;
  }
  addDocumentInfoPopup() {
    this.CreateAddDocumentsForm();
    this.documentInfoAddpopup = true;
    this.uploadedFile = "";
  }

  uploadFileEventHandler(e: UploadEvent) {
    const that = this;
    let randomValue = Math.floor((Math.random() * 10000))
    let fileType = this.documentInfoADDForm.value.fileType;
    let extension = e.files[0].extension;
    e.data = {
      description: this.employeeID + '_' + randomValue + '_' + fileType + extension
    };
  }



  documentDetailsADD() {
    this.FormValidateService_.validateAllFormFields_Empty(this.documentInfoADDForm);
    if (this.documentInfoADDForm.valid) {
      this.fileInfo.employeeID = this.employeeID
      this.documentInfoADDForm.addControl("employeeID", new FormControl(this.fileInfo.employeeID));
      this.apiMethodUrl = "api/Hr/EmployeeDocumentsADD";
      this.commonService_.postService(this.apiMethodUrl, this.documentInfoADDForm.value)
        .subscribe((result: any) => {
          if (result.code == 1) {
            let type = 'success';
            let content = 'Added Document successfully';
            this.commonService_.displayMessage(content, type)
            this.documentDetailsGET();
          }
        });
      this.documentInfoAddpopup = false;
    }
    else {
      this.FormValidateService_.validateAllFormFields(this.documentInfoADDForm);
    }
  }
  documentDetailsGET() {
    this.apiMethodUrl = "api/HR/EmployeeDocumentsGET"
    this.fileInfo.employeeID = this.employeeID;
    this.commonService_.getService(this.apiMethodUrl, "employeeID=" + this.fileInfo.employeeID)
      .subscribe((result: any) => {
        let DocumentsList = [];
        if (result.code == 1) {
          var documentsList = result.data;
          if (documentsList != null) {
            for (var document in documentsList) {
              DocumentsList.push(documentsList[document])
            }
            this.document = documentsList;
            this.items = this.document;
            if (DocumentsList.length > 0 && DocumentsList != null) {
              this.items.length > 10 ? this.pageable = true : this.pageable = false;
              this.loadItems(1);
            }
          }
          else{
            this.items=[];
            this.loadItems(1);
          }
          

        }
        else {
          let type = 'error';
          let content = 'No records found';
          this.commonService_.displayMessage(content, type)
          this.loadItems(1);
        }
      });

  }

  deleteDocumentPopUp(fileID: number) {
    this.confirm = true;
    this.fileInfo.fileID = fileID;
  }

  documentDetailsDELETE() {
    this.apiMethodUrl = 'api/Hr/EmployeeDocumentDelete'

    this.commonService_.postService(this.apiMethodUrl, this.fileInfo)
      .subscribe((result: any) => {
        if (result.code == 1) {
          let type = 'success';
          let content = 'Deleted Document successfully';
          this.commonService_.displayMessage(content, type)
          this.documentDetailsGET();

        }
      });
    this.confirm = false;
  }



  private loadItems(type: number): void {
    if (type == 1)
      this.skip = 0;
    this.gridView = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }
  close() {
    this.documentInfoAddpopup = false;
    this.confirm = false;
  }

  CreateAddDocumentsForm() {
    this.documentInfoADDForm = new FormGroup({
      fileName: new FormControl('', [Validators.required]),
      fileType: new FormControl('', [Validators.required]),
      filePath: new FormControl('',[Validators.required])
    })
    this.documentInfoADDForm.reset();
  }



}
