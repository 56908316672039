import { Component, OnInit } from '@angular/core';
import { CommonService } from "../../shared/services/common.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormValidateService } from "../../shared/services/form-validate.service";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import {Router} from '@angular/router';
import { UserComponent } from '../user.component';



@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.scss']
})
export class LeaveRequestComponent implements OnInit {
  leaveRequestForm: FormGroup; public gridData: GridDataResult;
  public minFrom: Date = new Date();minTo: Date = new Date();
  leaveRequestPopup: boolean = false; statusList: Array<{ "status": string, "value": number }>;
  typeList:Array<{"text":string,"value":number}>;

  employeeList: Array<{ "employeeName": string, "employeeID": number, "teamID": number }>; teamID: number; toEmployeeList: any = [];
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  
  private items: any[];accessLevelID:number;
  constructor(private CommonService_: CommonService, private FormValidateService_: FormValidateService,private Router:Router,private UserComponent:UserComponent) {
    this.statusList = [{ status: "Requested", value: 1 }, { status: "Approved", value: 2 }, { status: "Rejected", value: 3 }]
  }

  ngOnInit() {
    this.UserComponent.leaves = true;
    //this.employeeLeavesGET();
    this.LeaveTypeGET();
this.accessLevelID=this.CommonService_.retrieve("a_L");
  }

 

  employeeLeavesGET() {
    this.CommonService_.getService("api/Employee/EmployeeLeavesGET", '')
      .subscribe((response: any) => {
        let responseCode = response.code;
        if (responseCode == 1) {
          var leaveList = [];
          for (let i in response.data) {
            var status = this.statusList.filter(leaveStatus => leaveStatus.value === response.data[i].status)
            var leavetype;
            if(response.data[i].leaveType!=0)
            leavetype = this.typeList.filter(leaveType => leaveType.value === response.data[i].leaveType)
           
            leaveList.push({
              leaveID: response.data[i].leaveID,
              leaveReason: response.data[i].leaveReason,
              leaveRequestFrom: response.data[i].leaveRequestFrom,
              leaveRequestTo: response.data[i].leaveRequestTo,
              status: status!=undefined && status.length>0? status[0].status:"",
              statusValue: status!=undefined && status.length>0?status[0].value:"",
              remarks: response.data[i].remarks,
              leaveType:leavetype!=undefined&&leavetype.length>0 ?leavetype[0].text:response.data[i].leaveType==3?"Loss Of Pay ":""
            });
            this.teamID = response.data[i].teamID;
          }
                   
        }
        else if(response.code==0) {
          this.Router.navigateByUrl('sign-in');
        }
        this.items = leaveList;
      this.items.length > 10? this.pageable = true : this.pageable = false;
      this.loadItems();
      });
  }

   //paging
   private loadItems(): void {
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

//Calendar Event
  onChange(value: Date){
this.minTo=value;
this.leaveRequestForm.controls.leaveRequestTo.setValue('');
this.leaveRequestForm.controls.leaveRequestTo.reset();
  }

  leaveRequest() {
    this.minTo=new Date()
    this.createLeaveRequestForm();
    this.higherLevelEmployeesGET();
    this.leaveRequestForm.reset();
    this.leaveRequestPopup = true;
    if(this.accessLevelID==4){
      this.leaveRequestForm.controls.toEmployee.setValidators(null);
      this.leaveRequestForm.controls.toEmployee.updateValueAndValidity();
    }
     
  }
  higherLevelEmployeesGET() {
    var employeeIDs = [];
    this.CommonService_.getService("api/Employee/HigherLevelEmployeesGET", '')
      .subscribe((response: any) => {
        let employeeList = [], superiorEmployee = [];
        if (response.code == 1) {
          
          for(let i in response.data.objaccessLevel){
            employeeList.push({
              employeeID: response.data.objaccessLevel[i].accessLevelID,
              teamID: 0,
              employeeName: response.data.objaccessLevel[i].accessLevelType
            });
          }
          for (let i in response.data.objEmployeeDetails) {
            employeeList.concat({
              employeeID: response.data.objEmployeeDetails[i].employeeID,
              teamID: response.data.objEmployeeDetails[i].teamID,
              employeeName: response.data.objEmployeeDetails[i].firstName
            });
          }
          this.employeeList = employeeList;
        }

        this.employeeList.forEach(employee => {
          if (employee.teamID == this.teamID||employee.teamID==0) {
            superiorEmployee.push({
              employeeID: employee.employeeID,
              teamID: employee.teamID,
              employeeName: employee.employeeName
            });
            employeeIDs.push(employee.employeeID)
          }
        })
        //this.leaveRequestForm.controls.toEmployee.setValue(superiorEmployee);
        this.toEmployeeList = ("" + employeeIDs.join(", "));
      });
  }

public LeaveTypeGET(){
  this.CommonService_.getService("api/Employee/LeaveTypeGET",'')
  .subscribe((response:any)=>{
  var  leaveType=[];
    if(response.data!=null){
      response.data.forEach(element => {
        leaveType.push({"text":element.leavePolicyName,"value":element.leavePolicyID})
      });
      this.typeList=leaveType;
        this.employeeLeavesGET();      
    }
  })
}

  public valueChange(value: any) {
    var employeeIDs = [];
    value.forEach(employee => {
      employeeIDs.push(employee.employeeID);
    });
    this.toEmployeeList = ("" + employeeIDs.join(", "));
  }

  leaveRequestADD() {
    this.FormValidateService_.validateAllFormFields_Empty(this.leaveRequestForm);
    if (this.leaveRequestForm.valid) {
      var leaveRequestDetails = {
        leaveReason: this.leaveRequestForm.value.leaveReason,
        leaveRequestFrom: this.leaveRequestForm.value.leaveRequestFrom,
        leaveRequestTo: this.leaveRequestForm.value.leaveRequestTo,
        leaveType:this.leaveRequestForm.value.leavetype.value,
        toEmployee: this.toEmployeeList

      }
      this.CommonService_.postService("api/Employee/LeaveRequestADD", leaveRequestDetails)
        .subscribe((response: any) => {
          var responseCode = response.code
          if (responseCode == 1) {
            this.employeeLeavesGET();
            this.CommonService_.displayMessage("Leave Request Sent SuccessFully..", 'success');
          }
          else {
            this.CommonService_.displayMessage("Unable to process your request", 'error');
          }
        });
      this.leaveRequestPopup = false;
    }
    else {
      this.FormValidateService_.validateAllFormFields(this.leaveRequestForm);
    }

  }

  close() {
    this.leaveRequestForm.reset();
    this.leaveRequestPopup = false;
  }

  createLeaveRequestForm() {
    this.leaveRequestForm = new FormGroup({
      toEmployee: new FormControl('', [Validators.required]),
      leaveReason: new FormControl('', [Validators.required]),
      leaveRequestFrom: new FormControl(new Date(), [Validators.required]),
      leaveRequestTo: new FormControl(new Date(), [Validators.required]),
      leavetype:new FormControl('', [Validators.required])
    });
    this.leaveRequestForm.reset();
  }

   //Disable saturday and sunday in kendo datepicker
   public disabledDates = (date: Date): boolean => {
    return date.getDay() === 0 || date.getDay() === 6 ;
  }
}
