import { Component, OnInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service';
import { Router } from "@angular/router";
import { CommonService } from '../../../../shared/services/common.service'


@Component({
  selector: 'app-emergency-info',
  templateUrl: './emergency-info.component.html',
  styleUrls: ['./emergency-info.component.scss']
})
export class EmergencyInfoComponent implements OnInit {

  public emerganceForm: FormGroup;

  public familyInfoAddpopup;deletepopup: boolean = false;
  public emergancyinfo: GridDataResult;
  public pageable: boolean = false;
  public value: number = 0;
  public pageSize = 10;
  public skip = 0;
  private items: any[];
  public emergencyInfoID:any;
  public empID:any=this._CommonService.retrieve("E_Val");

  public emergancyContactAddPopup: boolean = false;

  constructor(private fb: FormBuilder,
    private fv: FormValidateService,
    private router: Router,
    private _CommonService: CommonService
  ) {
    this.emerganceForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      relationship: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      addressLine1: new FormControl('', [Validators.required]),
      addressLine2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      currEmployeeID:new FormControl('')
    });

  }

  ngOnInit() {
    this.empID!=-1? this.EmergancyInfoGet():this.items=[];    
  }

  //Add popup

  Add() {
    this.emerganceForm.reset();
    this.emergancyContactAddPopup = true;
  }

  //close popup

  close() {
    this.emergancyContactAddPopup = false;
    this.deletepopup=false;
  }

  //To retrieve emergency info
  EmergancyInfoGet() {
    this._CommonService.getService("api/Employee/EmergencyContactInfoGET", "currEmployeeID="+this.empID).subscribe((result: any) => {
      if (result.code == 1) {
        var emergencyDetails = [];
        emergencyDetails = result.data;
        if (emergencyDetails != null) {        
          this.items = emergencyDetails;
          this.items.length > 10 ? this.pageable = true : this.pageable = false;
          this.loadItems(0);
        }
        else {
          this.items = [];
          this.loadItems(0);
        }
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
    });

  }


  //paging
  private loadItems(type: any): void {
    if (type == 1)
      this.skip = 0;
    this.emergancyinfo = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems(0);
  }

  //Add functionality.

  EmergencyInfoAdd() {
   this.fv.validateAllFormFields_Empty(this.emerganceForm)
    if (this.emerganceForm.valid) {
      this.emerganceForm.controls.currEmployeeID.setValue(this.empID); 
      this._CommonService.postService("api/Employee/EmergencyContactInfoADD", this.emerganceForm.value).subscribe((result: any) => {
        if (result.code == 1) {
          this._CommonService.displayMessage("Added Successfully !!!", "success");
          this.EmergancyInfoGet();
        }
        else if (result.code == -8) {
          this._CommonService.displayMessage("Already Exists..!!", "error");
        }
        else if (result.code == 0) {
          this.router.navigateByUrl('sign-in');
        }
        else {
          this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
        }
        this.emergancyContactAddPopup = false;
      });
    } else {
      this.fv.validateAllFormFields(this.emerganceForm);
    }
  }

  //delete functionality.

  deletePopup(ID){
    this.emergencyInfoID=ID;
    this.deletepopup=true;
  }

  Delete() {
    this._CommonService.getService("api/Employee/EmergencyContactInfoDELETE", "emergencyContactID=" + this.emergencyInfoID).subscribe((result: any) => {
      if (result.code == 1) {
        this.loadItems(1);
        this.deletepopup=false;
        this._CommonService.displayMessage("Deleted Successfully !!!", "success");
        this.EmergancyInfoGet();
      }
      else if (result.code == 0) {
        this.router.navigateByUrl('sign-in');
      }
      else {
        this._CommonService.displayMessage("!oops There was an error please try after some time....", "error");
      }
      this.emergancyContactAddPopup = false;
    });
  }

  //page navigation

  pageNavigation() {
    this.router.navigateByUrl('user/employee/educational-info');
  }

  handle(e) {
    var k = e.charCode;
    return ((k >= 48 && k <= 57));
  }
}
