import { Component, OnInit } from '@angular/core';
import { emailFormat } from '../../../../shared/constants/constants';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormValidateService } from '../../../../shared/services/form-validate.service';
import { Router } from "@angular/router";
import { CommonService } from '../../../../shared/services/common.service';
import {UserComponent} from '../../../user.component';

@Component({
  selector: 'app-employee-basicinfo',
  templateUrl: './employee-basicinfo.component.html',
  styleUrls: ['./employee-basicinfo.component.scss']
})
export class EmployeeBasicinfoComponent implements OnInit {
  //Global Variable
  apiMethodUrl: string;
  objBasicInfo: any;
  objAddressInfo: any;

  //maritalStatusVariables
  maritalStatusList: Array<{ text: string, value: number }> = [];
  defaultStatus: any = { text: "Select Marital Status", value: 0 }

  public basicInfoForm: FormGroup;
  public state:boolean=false;isDisabled:boolean=true;
  public disableEmail:boolean=false;
  public toMaxDate:Date;
  //GenderList Variables
  genderList: Array<{ text: string, value: number }> = [];
  defaultGender: any = { text: "Select Gender", value: 0 }


  constructor(
    private fb: FormBuilder,
    private fv: FormValidateService,
    private router: Router,
    private commonService_: CommonService,
    private UserComponent:UserComponent
  ) {
    this.maritalStatusList = [{ text: "Married", value: 1 }, { text: "Widowed", value: 2 }, { text: "Separated", value: 3 }, { text: "Divorced", value: 4 }, { text: "Single", value: 5 }];
    this.genderList = [{ text: "Male", value: 1 }, { text: "Female", value: 2 }]
    this.basicInfoForm = this.fb.group({
      emailAddress: new FormControl('', [Validators.required, Validators.pattern(emailFormat)]),
      alternativeEmailAddress: new FormControl('', [Validators.required, Validators.pattern(emailFormat)]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(''),
      phoneNumber: new FormControl('', [Validators.required]),
      middleName: new FormControl(''),
      nationality: new FormControl('', [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      maritalStatus: new FormControl(null, [Validators.required]),
      dateOfBirth: new FormControl('', [Validators.required]),
      anniversaryDate: new FormControl(new Date(1900, 0, 1), [Validators.required]),
      bloodGroup: new FormControl('', [Validators.required]),
      alternativePhoneNumber: new FormControl('', [Validators.required]),
      passportNumber: new FormControl(''),

      addressLine1: new FormControl('', [Validators.required]),
      addressLine2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),

      curaddressLine1: new FormControl('', [Validators.required]),
      curaddressLine2: new FormControl(''),
      curcity: new FormControl('', [Validators.required]),
      curstate: new FormControl('', [Validators.required]),
      curzip: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    

    if(this.commonService_.retrieve("E_Val")!=-1){ 
      this.basicInfo_GET()}
    else{
      this.basicInfoForm.reset();
      this.basicInfoForm.controls.phoneNumber.setValue("+91-");
      this.basicInfoForm.controls.nationality.setValue("INDIAN"); 
    }
    this.disableEmail=this.commonService_.retrieve("E_Val")==-1?false:true;
    this.toMaxDate=this.commonService_.DateConversion(new Date());
  }

  maritalStatusChange(maritalStatus) {
    if (maritalStatus.value == 5) {
      this.isDisabled=true;
      this.basicInfoForm.controls.anniversaryDate.clearValidators();
      this.basicInfoForm.controls.anniversaryDate.updateValueAndValidity();
    }
    else {
      this.isDisabled=false;
      this.basicInfoForm.controls.anniversaryDate.setValidators(Validators.required);
      this.basicInfoForm.controls.anniversaryDate.updateValueAndValidity();
    }
  }

  populateCurrentAddress(event) {
    if (event.target.checked) {
      this.basicInfoForm.controls.curaddressLine1.setValue(this.basicInfoForm.controls.addressLine1.value);
      this.basicInfoForm.controls.curaddressLine2.setValue(this.basicInfoForm.controls.addressLine2.value);
      this.basicInfoForm.controls.curcity.setValue(this.basicInfoForm.controls.city.value);
      this.basicInfoForm.controls.curstate.setValue(this.basicInfoForm.controls.state.value);
      this.basicInfoForm.controls.curzip.setValue(this.basicInfoForm.controls.zip.value);

    } else {
      this.state=false;
      this.basicInfoForm.controls.curaddressLine1.setValue('');
      this.basicInfoForm.controls.curaddressLine2.setValue('');
      this.basicInfoForm.controls.curcity.setValue('');
      this.basicInfoForm.controls.curstate.setValue('');
      this.basicInfoForm.controls.curzip.setValue('');
    }
  }

  basicInfo() {
    this.fv.validateAllFormFields_Empty(this.basicInfoForm);
    if (this.basicInfoForm.valid) {
      var addresses: any = []
      this.objAddressInfo = {
        addressLine1: this.basicInfoForm.controls.addressLine1.value,
        addressLine2: this.basicInfoForm.controls.addressLine2.value,
        city: this.basicInfoForm.controls.city.value,
        state: this.basicInfoForm.controls.state.value,
        zip: this.basicInfoForm.controls.zip.value
      }
      addresses.push(this.objAddressInfo);
      if(this.state==true){addresses.push(this.objAddressInfo);}
      else{
        this.objAddressInfo = {
          addressLine1: this.basicInfoForm.controls.curaddressLine1.value,
          addressLine2: this.basicInfoForm.controls.curaddressLine2.value,
          city: this.basicInfoForm.controls.curcity.value,
          state: this.basicInfoForm.controls.curstate.value,
          zip: this.basicInfoForm.controls.curzip.value
        }
      }

      addresses.push(this.objAddressInfo);  
      this.objBasicInfo = {
        empCode:"",
        firstName: this.basicInfoForm.controls.firstName.value,
        lastName: this.basicInfoForm.controls.lastName.value,
        emailAddress: this.basicInfoForm.controls.emailAddress.value,
        phoneNumber: this.basicInfoForm.controls.phoneNumber.value,
        middleName: this.basicInfoForm.controls.middleName.value,
        nationality: this.basicInfoForm.controls.nationality.value,
        gender: this.basicInfoForm.controls.gender.value.text,
        maritalStatus: this.basicInfoForm.controls.maritalStatus.value.text,
        dateOfBirth:new Date(this.basicInfoForm.controls.dateOfBirth.value).toLocaleDateString(),
        anniversaryDate: this.basicInfoForm.controls.maritalStatus.value.value == 5 ? "" : new Date(this.basicInfoForm.controls.anniversaryDate.value).toLocaleDateString(),
        bloodGroup: this.basicInfoForm.controls.bloodGroup.value,
        alternativePhoneNumber: this.basicInfoForm.controls.alternativePhoneNumber.value,
        alternativeEmailAddress: this.basicInfoForm.controls.alternativeEmailAddress.value,
        passportNumber: this.basicInfoForm.controls.passportNumber.value,
        currEmployeeID:this.commonService_.retrieve("E_Val"),
        addressList: addresses
      };
      this.apiMethodUrl = "api/Employee/EmployeeBasicInfoAdd";
      this.commonService_.postService(this.apiMethodUrl, JSON.stringify(this.objBasicInfo))
        .subscribe((result: any) => {
          if (result.code == 1) {           
            if(this.commonService_.retrieve("E_Val")==-1)     {
              this.commonService_.store("E_Val",result.data);
            }      
            else if(this.commonService_.retrieve("E_Val")==0){
              this.commonService_.store("u_N",this.objBasicInfo.firstName);
              this.UserComponent.employeeNameUpdate();
            }    
            this.commonService_.displayMessage("Basic information Updated Successfully !!!", "success");
            this.basicInfo_GET();
            this.router.navigateByUrl('user/employee/family-info')
          }
          else if (result.code == -8) {
            this.commonService_.displayMessage("Already exists...!!", "error");
          }
          else if (result.code == 0) {
            this.router.navigateByUrl('sign-in');
          }
          else {
            this.commonService_.displayMessage("!oops There was an error please try after some time....", "error");
          }
        });
    } else {
      this.fv.validateAllFormFields(this.basicInfoForm);
    }
  }



  // to get basic info  
  basicInfo_GET() {
    this.apiMethodUrl = "api/Employee/EmployeeInfoGet";
    this.commonService_.getService(this.apiMethodUrl, "currEmployeeID="+this.commonService_.retrieve("E_Val"))
      .subscribe((result: any) => {
        if (result.code == 1) {       
       
          this.basicInfoForm.controls.firstName.setValue(result.data.firstName);
          this.basicInfoForm.controls.lastName.setValue(result.data.lastName);
          this.basicInfoForm.controls.emailAddress.setValue(result.data.emailAddress);
          var phoneno="";
          if(result.data.phoneNumber!=undefined&& result.data.phoneNumber!="" && result.data.phoneNumber.indexOf('+91-')<0){
            phoneno="+91-"+result.data.phoneNumber;
          }else{
            if(result.data.phoneNumber==undefined|| result.data.phoneNumber==""){
            phoneno="+91-";
            }else{
              phoneno=result.data.phoneNumber;
            }
          }
          this.basicInfoForm.controls.phoneNumber.setValue(phoneno);
          this.basicInfoForm.controls.middleName.setValue(result.data.middleName);
          this.basicInfoForm.controls.nationality.setValue(result.data.nationality!=undefined && result.data.nationality!=""?result.data.nationality:"INDIAN");
          var gendervelue = this.genderList.filter(
            item => item.text === result.data.gender);


          var maritalStatusvalue = this.maritalStatusList.filter(
            item => item.text === result.data.maritalStatus);

          if (gendervelue != undefined && gendervelue.length > 0)
            this.basicInfoForm.controls.gender.setValue({ "text": result.data.gender, "value": gendervelue[0].value });

          if (maritalStatusvalue != undefined && maritalStatusvalue.length > 0)
            this.basicInfoForm.controls.maritalStatus.setValue({ "text": result.data.maritalStatus, "value": maritalStatusvalue[0].value });

          this.basicInfoForm.controls.dateOfBirth.setValue(new Date(result.data.dateOfBirth));
          if (result.data.maritalStatus != "Single"){
            this.isDisabled=false;
            this.basicInfoForm.controls.anniversaryDate.setValue(new Date(result.data.anniversaryDate));
          }
          else{
            this.isDisabled=result.data.maritalStatus==''?false:true;
            this.basicInfoForm.controls.anniversaryDate.setValue(new Date(1900,0,1));
          }
           

          this.basicInfoForm.controls.bloodGroup.setValue(result.data.bloodGroup);
          this.basicInfoForm.controls.alternativePhoneNumber.setValue(result.data.alternativePhoneNumber);
          this.basicInfoForm.controls.alternativeEmailAddress.setValue(result.data.alternativeEmailAddress);
          this.basicInfoForm.controls.passportNumber.setValue(result.data.passportNumber);

          if (result.data.addressList != undefined && result.data.addressList.length > 0) {
            this.basicInfoForm.controls.addressLine1.setValue(result.data.addressList[0].addressLine1);
            this.basicInfoForm.controls.addressLine2.setValue(result.data.addressList[0].addressLine2);
            this.basicInfoForm.controls.city.setValue(result.data.addressList[0].city);
            this.basicInfoForm.controls.state.setValue(result.data.addressList[0].state);
            this.basicInfoForm.controls.zip.setValue(result.data.addressList[0].zip);
            this.state=false;
          }

          if (result.data.addressList != undefined && result.data.addressList.length > 1) {
            this.basicInfoForm.controls.curaddressLine1.setValue(result.data.addressList[1].addressLine1);
            this.basicInfoForm.controls.curaddressLine2.setValue(result.data.addressList[1].addressLine2);
            this.basicInfoForm.controls.curcity.setValue(result.data.addressList[1].city);
            this.basicInfoForm.controls.curstate.setValue(result.data.addressList[1].state);
            this.basicInfoForm.controls.curzip.setValue(result.data.addressList[1].zip);

            if(  result.data.addressList[0].addressLine1==result.data.addressList[1].addressLine1 
              && result.data.addressList[0].addressLine2==result.data.addressList[1].addressLine2
              && result.data.addressList[0].city==result.data.addressList[1].city
              && result.data.addressList[0].state==result.data.addressList[1].state
              && result.data.addressList[0].zip==result.data.addressList[1].zip){
                this.state=true;
            }else{
              this.state=false;
            }
          }
        }
        else if (result.code == 0) {
          this.router.navigateByUrl('sign-in');
        }
        else {
          this.commonService_.displayMessage("!oops There was an error please try after some time....", "error");
        }
      }
      );
  }


}
