import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ManagementComponent} from './management.component'
import {LeavepolicySettingsComponent} from '../management/leavepolicy-settings/leavepolicy-settings.component'
import { AccesslevelSettingsComponent } from './accesslevel-settings/accesslevel-settings.component';
import { RoleSettingsComponent } from './role-settings/role-settings.component';


const routes: Routes = [
    {
        path: '', component: ManagementComponent,
        children: [   
            {
                path: '',pathMatch: 'full', component: LeavepolicySettingsComponent,data:{title:'Policy Settings'}
            },       
            {
                path: 'policy-settings', component: LeavepolicySettingsComponent,data:{title:'Policy Settings'}
            },
            {
                path:'role-settings',component:RoleSettingsComponent,data:{title:'Roles'}
            },
            {
                path:'access-level',component:AccesslevelSettingsComponent,data:{title:'Access Levels'}
            }
        ]       
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ManagementRoutingModule{}